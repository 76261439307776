import React from 'react'
import { useParams } from 'react-router-dom';
import { useUserLang } from '../contexts/LanguageSwitcherProvider'
import { t } from 'i18next';

import useGetWPAxios from '../api/useGetWPAxios';

import LoadingOutput from '../components/Output/LoadingOutput/LoadingOutput';
import Container from '../components/Layout/Container';



const HowToPlayPage = () => {

    const params = useParams()
	const { lang } = useUserLang()
    const { data, error } = useGetWPAxios('wp/v2/get_post_by_lang?slug=' + params.slug + '&lang=' + lang + '&post_type=post')

	if (!data || error) {
		return <LoadingOutput>{ t("Fetching page...")}</LoadingOutput>
	}

    return (
        <div className="single-blog-page">
            <Container>
                <div className="col-12 single-post-container">
                    <div className="single-post">
                        <h1>{ data.post_title }</h1>
                        <div className="blog-content-box" dangerouslySetInnerHTML={{__html: data.content}} />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default HowToPlayPage