import React from 'react'
import styled from 'styled-components'

export default function PaymentLogo({ appOptions }){

	return (
		<div className='container'>
			<FooterLogos>
				{
					appOptions && appOptions.acf && appOptions.acf.payment_methods && appOptions.acf.payment_methods.map(( item, index ) => {
						return (
							<div key={index} className={'logo-box grayscale'}>
								<img alt="Footer Logo" src={item.image.url} />		
							</div>
						)
					})
				}
			</FooterLogos>
		</div>
	)
}

const FooterLogos = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	max-width: 1300px;
	margin: 0 auto;
	padding-top: 25px;
	img{
		object-fit: contain;
		@media (min-width: 0px) and (max-width: 767px){
			padding: 10px;
			width: 100px;
			height: 50px;
		}
		@media (min-width: 768px){
			padding: 15px;
			width: 140px;
			height: 80px;
		}
	}
`