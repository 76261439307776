import React from 'react'
import HeroBannerSlider from '../Utils/HeroBannerSlider/HeroBannerSlider'

export default function MainLayout({ children }){
    return (
        <>
            <HeroBannerSlider />
            { children }
        </>
    )
}