import React  from "react";
import TextInput from '../../../components/Utils/TextInput/TextInput'
import SelectInput from '../../../components/Utils/SelectInput/SelectInput'
import RadioButton from "../../Utils/RadioButton/RadioButton"

export default function PersinalInformation({title, firstname, surname, gender, nationality, birthYear, birthDay, birthMonth}){

    
    
    const monthNames = [ 
        { value : 1 , label: "January" },
        { value : 2 , label: "February" },
        { value : 3 , label: "March" },
        { value : 4 , label: "April" },
        { value : 4 , label: "May" },
        { value : 6 , label: "June" },
        { value : 7 , label: "July" },
        { value : 8 , label: "August" },
        { value : 9 , label: "September" },
        { value : 10 , label: "October" },
        { value : 11 , label: "November" },
        { value : 12 , label: "December" }
    ];

    const titleOptions = [
        { value : 'Mr.' , label: "Mr."},
        { value : 'Ms.' , label: "Ms."},
        { value : 'Mrs.' , label: "Mrs."},
        { value : 'Miss' , label: "Miss"}
    ]

    const daysInMonth = [];

    for(let i = 1 ; i < 32 ; i++){
        daysInMonth.push( {
            value:i,
            label:i
        });
    }

    const yearsOptions = [];

    let yearNow = new Date().getFullYear();
    for(let i = yearNow ; i >= 1900 ; i--){
        yearsOptions.push( {
            value:i,
            label:i
        });
    }

    return (
        <fieldset className="Column">
        
            <SelectInput label="Title *" options={titleOptions}  setValue={title.setTitle } required={true} value={title.title} />

            <TextInput 
                label="First name *"
                type="text"
                required={true}
                value={firstname.firstname && firstname.firstname}
                setValue={firstname.setFirstname}
                preventSpecialChars={true}
            />

            <TextInput 
                label="Surname *"
                type="text"
                required={true}
                value={surname.surname && surname.surname}
                setValue={surname.setSurname}
                preventSpecialChars={true}
            />

            <div className={"radio-wrap" + (gender.gender===null ? ' required-input' : '')}>
                <label>Gender *</label>
                <div className="radio-gender-wrap">
                    <RadioButton 
                        label="Male"
                        value="M"
                        name="gender"
                        selectedValue={gender.gender}
                        setValue={gender.setGender}
                    />
                    <RadioButton 
                        label="Female"
                        value="F"
                        name="gender"
                        selectedValue={gender.gender}
                        setValue={gender.setGender}
                    />
                </div>
            </div>

            <div className="form-row birth-row">
                <label>Date of birth *</label>
                <div className="row">
                    <SelectInput className="col-sm-4" options={daysInMonth}  setValue={birthDay.setBirthDay } required={true} value={birthDay.birthDay} placeholder="Day"  />
                    <SelectInput className="col-sm-4" options={monthNames}  setValue={birthMonth.setBirthMonth } required={true} value={birthMonth.birthMonth} placeholder="Month"   />
                    <SelectInput className="col-sm-4" options={yearsOptions}  setValue={birthYear.setBirthYear } required={true} value={birthYear.birthYear} placeholder="Year"   />
                </div>
            </div>
            
        </fieldset>
    );
}