import React from 'react'
import styled from 'styled-components/macro'

export default function LobbyAside(){

    return (
        <LobbyAsideWrap id="lobby-aside">
            <ImgWrap>
                <img alt="MiMa Poker" src='/images/logos/mima-poker-logo.svg' />
            </ImgWrap>
        </LobbyAsideWrap>
    )
}

const LobbyAsideWrap = styled.div ``

const ImgWrap = styled.div `
    display: flex;
    justify-content: center;
    img {
        width: 70%;
    }
`