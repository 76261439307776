import React from 'react'
import styled from 'styled-components'

export default function AboutUsePage() {

    return (
        <MainContainer>
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-xl-11">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-6">
                                <MainIntro>
                                    <h1>About Us</h1>
                                    <p>MiMa Casino officially entered the gaming arena in 2023 under the guidance of an experienced team of poker lovers.</p>
                                </MainIntro>
                            </div>
                            <div className="col-xl-6">
                                <video 
                                muted={true} 
                                autoPlay={true} 
                                loop={true} 
                                playsInline={true} 
                                src="https://api-content.mima.casino/wp-content/uploads/2024/02/mima-poker-promo-video.mov"
                                alt='MiMa Poker Promo Video' />
                            </div>
                        </div>
                    </div>

                    <MainContent className="col-xl-6">

                        <h3>The Birth of an Idea</h3>
                        <p>The idea was born during the COVID-19 pandemic, an era marked by significant global challenges. Inspired by the need for human interaction during times of isolation, the team was united by the idea of creating a new, unique product at the intersection of online and live gaming.</p> 
                        
                        <img src="/images/about/mima-cards.jpg" alt='mima cards' />
                        
                        <h3>Behind Our Revolutionary Approach</h3>
                        <p>Our approach is nothing short of revolutionary, moving far beyond the simplistic setup of typical casino sites.</p>
                        <p>We faced a steady, challenging journey. Despite online poker's start in 1998, innovation has been rare. Wanting to break free from algorithms and RNGs, MiMa has developed a way to digitize the game but yet bring back the human element of a real live dealer. Over 3 careful years of meticulous planning and development, we are proud to be running the world’s first algorithm-free, machine-free online live casino platform.</p>

                        <video 
                        muted={true} 
                        autoPlay={true} 
                        loop={true} 
                        playsInline={true} 
                        src="https://api-content.mima.casino/wp-content/uploads/2023/07/07062.mp4"
                        alt='MiMa Poker Promo Video' />

                        <p>We developed a completely new technology where we put live dealers in specialized studios, and stream them live for our players. To make it all possible, we developed a user-oriented game interface that is directly connected with the live stream. This way, we digitalize the game without taking out the human factor. Finally, we are proud to say that by doing this, we said no to algorithmic shuffling and set a new standard for what online gaming can be. Now you can enjoy the pure game in a fair, bot-free environment.</p>

                        <img src="/images/about/mima-table-girls.jpg" alt='mima table dealers girls'/>

                        <h3>MiMa Team</h3>
                        <p>MiMa Casino is supported by a team of outstanding talents, each committed to delivering an exceptional gaming experience. Our team's collective ambition and creative efforts ensure that every experience at MiMa Casino is unique and deeply satisfying. Simply check out MiMa Casino - you could find it too good to leave.</p>

                        <img src="/images/about/mima-table-girls-2.jpg" alt='studio'/>

                        <h3>Safety & Security</h3>
                        <p>Your personal information is safely held, as we uphold the strictest information security standards on our platform. Furthermore, we guarantee secure deposits and withdrawals, enforcing robust security protocols for every financial transaction.</p>
                        
                        <h3>Responsible Gaming</h3>
                        <p>Safety and fairness in gaming are our top priorities. Our team goes the extra mile to ensure everyone's satisfaction.</p>

                    </MainContent>

                </div>
            </div>
        </MainContainer>
    )
}

const MainIntro = styled.div`
    max-width: 560px;
`

const MainContainer = styled.section`
    padding: 5rem 0;
    padding-bottom: 10rem;
    h1, h3{
        font-size: 2.125rem;
        color: var(--orange);
        margin: 0;
        margin-top: 3rem;
    }
    p{
        font-size: 1.25rem;
        line-height: 1.625;
        margin: 1rem 0;
    }
    img, video{
        display: block;
        max-width: 100%;
        height: auto;
        border: 1px solid var(--orange-hover);
        border-radius: 1rem;
        box-shadow: 0px 10px 25px black;
    }
`

const MainContent = styled.div`
    margin-top: 2rem;
    img, video{
        @media (min-width: 0px) and (max-width: 1023px){
            margin: 2rem 0;
        }
        @media (min-width: 1024px){
            margin: 7rem 0;
            transform: scale(1.15);
        }
    }
`