import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import moment from "moment-timezone"
import axios from "axios"

import { useUserSession } from "../contexts/UserSessionProvider";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import TextInput from "../components/Utils/TextInput/TextInput";
import Spacer from "../components/Utils/Spacer/Spacer";
import { t } from "i18next";


// This values are the props in the UI
const currency = "USD";
const style = {"layout":"vertical"};
// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner }) => {

    const { userSession } = useUserSession()
    const navigate = useNavigate()
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [ amount, setAmount ] = useState('');

    function getQueryString(name) {
        let newname = name.replace("/[]/", "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + newname + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }


    /* eslint-disable */
    useEffect(() => {
        
        if(getQueryString('amount')){
            setAmount(getQueryString('amount'));
        }
    },[])

    /* eslint-disable */
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner])
    /* eslint-disable */


    return (<>

            <Spacer />

            { (showSpinner && isPending) && <div className="spinner" /> }
            
            <div className="text-wrap">
                <h1>{ t("Deposit with PayPal")}</h1>
                <p>
                    { t("We understand the importance of quick & efficient transfers, which is why we have implemented a system that ensures all PayPal deposits are processed in a timely manner.")} 
                </p>
                <p>
                    { t("If your deposit is made between 8am CET to 10pm CET times of the day, it will be transferred immediately. This means that you can access your funds in almost real-time (please allow us approx. 15 - 30 minutes of processing time).")} 
                </p>
                <p>
                    { t("However, if you make a deposit from 10pm to 8am times of the day, it will be processed and transferred to your account on the next business day. This means that you can expect to see your funds in your account the very next morning, ready for you to use as you wish.")}
                </p>
                <p>
                    { t("Enjoy the reliable and hassle-free payment experience we offer. If you have any questions or concerns about PayPal deposits, please don't hesitate to contact our customer support team, who will be happy to assist you.")}
                </p>
                <p>
                    { t("Contact at ")} <a href="mailto:info@mimasupport.com">info@mimasupport.com</a>
                </p>
            </div>
            <TextInput 
                label={ t("Amount ")}
                type="text"
                textType="number"
                inputmode="numeric"
                required={true}
                value={amount && amount}
                setValue={setAmount}
                className="mb-2"
            />

            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    description:(t("User ID: "))+userSession.id+ t(" | Username: ")+userSession.username+ t(" | User email: ")+userSession.email,
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {

                        const dateTime = new Date();
                        const txDate = moment(dateTime).utc('+2').format('DD/MM/YYYY HH:mm') + ' UTC 2.'
                        const title = txDate + ' - New PayPal Transaction from: ' + userSession.username
                        
                        let description = "Paypal txn ID: " + data.paymentID + "\n"
                        description += "User ID: " + userSession.id + "\n"
                        description += "Username: " + userSession.username + "\n"
                        description += "E-mail: " + userSession.email + "\n"
                        description += "PayPal Amount: " + amount + "$\n"
                        description += "Date: " + txDate + "\n"

                        /* let bitrixUrl = "https://mima.bitrix24.com/rest/6/xmcs3k32u7buky7m/task.item.add.json?TASKDATA[TITLE]=" + encodeURIComponent(title) + "&TASKDATA[RESPONSIBLE_ID]=6&TASKDATA[CREATED_BY]=6&TASKDATA[DESCRIPTION]=" + encodeURIComponent(description) */

                        const bitrixUrl = "https://mima.bitrix24.com/rest/6/xmcs3k32u7buky7m/task.item.add.json?TASKDATA[TITLE]=" + encodeURIComponent(title) + "&TASKDATA[RESPONSIBLE_ID]=1&TASKDATA[GROUP_ID]=192&TASKDATA[ACCOMPLICES][]=82&TASKDATA[ACCOMPLICES][]=108&TASKDATA[ACCOMPLICES][]=308&TASKDATA[ACCOMPLICES][]=6&TASKDATA[ACCOMPLICES][]=302&TASKDATA[ACCOMPLICES][]=114&TASKDATA[CREATED_BY]=1&TASKDATA[DESCRIPTION]=" + encodeURIComponent(description)

                        axios.post(bitrixUrl).then((response)=>{
                            navigate('/my-account/deposit/thank-you-page')
                        })

                    });
                }}
                onError={function (data, actions) {
                    return actions.order.capture().then(function () {
                        navigate('/my-account/deposit/failed-page')
                    });
                }}
            />
            
            <Spacer />

        </>
    );
}

export default function App() {
	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-xl-6">
                    <PayPalScriptProvider
                        options={{
                            "client-id": "AXL6LJjvZnspiRHzOQMEXsG6F9Argj83yOjIsY1yVjMwU86J-vxHWOJAKQCfKdBrRwpVmDg8BKXD5kiR",
                            components: "buttons",
                            currency: "USD"
                        }}
                    >
                        <ButtonWrapper
                            currency={currency}
                            showSpinner={false}
                        />
                    </PayPalScriptProvider>
                </div>
            </div>
		</div>
	);
}