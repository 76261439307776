let liveTables = []
//realy money
liveTables[1]       = 'Area 52'
liveTables[2]       = 'The Poker Matrix'
liveTables[3]       = 'Royal Flush Lounge'
liveTables[4]       = 'Bluffing Bonanza'
liveTables[5]       = 'Full House Frenzy'
liveTables[6]       = 'All-In Arena'
liveTables[7]       = 'The Final Table'
liveTables[8]       = 'The Chip Chateau'
liveTables[9]       = 'Ace\'s Haven'
liveTables[10]      = 'Poker Paradiso'
liveTables[11]      = 'The Virtual Vault'
liveTables[12]      = 'Shuffle & Showdown'
liveTables[13]      = 'The Bluffing Club'
liveTables[14]      = 'Chip City'
liveTables[15]      = 'Ace\'s Cyber Deck'
liveTables[16]      = 'High Stakes Hideout'
liveTables[17]      = 'The River\'s Edge'
liveTables[18]      = 'Pocket Rockets Club'
liveTables[19]      = 'House of Cards'
liveTables[20]      = 'King\'s Courtroom'
liveTables[21]      = 'The Showdown Saloon'
liveTables[22]      = 'All-In Oasis'
liveTables[23]      = 'The Chip Stack Shack'
liveTables[24]      = 'Flush Fever Foyer'
liveTables[25]      = 'Hold\'em Hideout'
liveTables[26]      = 'The Betting Boutique'
liveTables[101]     = 'Virtual Flush'
liveTables[102]     = 'Poker Reality'
liveTables[103]     = 'Poker Night Online'
liveTables[104]      = 'Poker Paradiso'
liveTables[105]      = 'The Virtual Vault'
liveTables[106]      = 'Shuffle & Showdown'
liveTables[107]      = 'The Bluffing Club'
liveTables[108]      = 'Chip City'
liveTables[109]       = 'The Chip Chateau'


let onlineTables = []
onlineTables[101]     = 'Virtual Flush'
onlineTables[102]     = 'Poker Reality'
onlineTables[103]     = 'Poker Night Online'
onlineTables[104]     = 'Poker Paradiso'
onlineTables[105]     = 'The Virtual Vault'
onlineTables[106]     = 'Shuffle & Showdown'
onlineTables[107]     = 'The Bluffing Club'
onlineTables[108]     = 'Chip City'
onlineTables[109]     = 'The Chip Chateau'

export { liveTables, onlineTables }