import { css } from "styled-components";

export const buttonElementCss = css`
    cursor: pointer;
	position: relative;
	overflow: hidden;
	border: none;
	outline: none;
	text-decoration: none;
	-webkit-appearance: none;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	border-radius: 10px;
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	padding: 10px 15px;
	min-height: 35px;
	color: #FFFFFF;
	border: 2px solid;

	${props => props.secondary==='true' ? css`
		background: none;
		@media (min-width: 1025px){
			&:hover{ 
				border-color: var(--${props => props.colorTheme});
				${props => props.light==='true' ? css`
					color: var(--${props => props.colorTheme});
				` : ``}
			}
		}
	` : css`
		background: linear-gradient(to top, var(--${props => props.colorTheme + '-hover'}), var(--${props => props.colorTheme}));
		border-color: var(--${props => props.colorTheme});
		box-shadow: 0px 5px 15px #00000059;
		@media (min-width: 1025px){
			&:hover {
				border-color: white;
				${props => props.light==='true' ? css`
					border-color: var(--${props => props.colorTheme}-hover);
				` : ``}
			}
		}
	`}

	background-size: 200% 100%;
	background-position: right bottom;
	
	@media (min-width: 0px) and (max-width: 767px){
		font-size: 16px;
		padding: 8px 25px;
	}
	@media (min-width: 768px){
		min-width: 110px;
	}
	@media(min-width: 1200px) {
		padding: 10px 25px;
	}
	@media(min-width: 1600px) {
		height: 50px;
	}

	${props => props.light==='true' && props.secondary==='true' ? css`
		color: black;
	` : ``}

	&.w-100{
		width: 100% !important;
	}

	&.btn-outline{
		border: 1px solid #099ed4;
		color: #2aa9d8;
		background: none;
		@media (min-width: 1025px){
			&:hover{
				background: #2aa9d8;
				color: white;
			}
		}
	}

	&.dark{
		box-shadow: none;
		color: white;
		background-color: black;
		border: none;
	}

	&.dark-cube {
		box-shadow: none;
		color: #FFFFFF;
		background-color: black;
		border-radius: 10px;
		outline: none;
		border: none;
		@media (min-width: 1025px){
			&:hover{
				box-shadow: none;
			}
		}
	}

	&.btn-icon{
		padding-left: 15px;
		img{
			width: auto;
			height: 24px;
			margin-right: 15px;
		}
	}

	&.btn-small{
		padding: 0px 10px;
		font-size: 14px;
		min-height: 32px;
	}

	&:focus{
		outline: none;
		border-color: white;
	}

	img{
		width: 24px;
		height: auto;
		margin-right: 10px;
	}

	${props => props.disabled ? `opacity: 0.2; pointer-events: none;` : ``}

`