import React from "react";
import './OurTeam.scss'
import Container from "../Layout/Container";
import useGetWPAxios from '../../api/useGetWPAxios';
import OurTeamBox from "./OurTeamBox";
import styled from "styled-components";

export default function OurTeam() {
    
    const { data } = useGetWPAxios('wp/v2/pages?slug=our-team')

    if(!data) return <></>
    if(data && !data[0]) return <></>

    return(
        <TeamSection className="our-team-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="our-team-title-area">
                            <Container>
                                <div className="title-box col-12">
                                    <h1 className="our-team-title">{ data[0].title.rendered }</h1>
                                    <Description dangerouslySetInnerHTML={{ __html: data[0].content.rendered }} />
                                </div>
                            </Container>
                        </div>
                        <div className="blog-post-section">
                            <Container>
                                {
                                    data[0].acf && data[0].acf.team_member.map((item, key) => {
                                        return(
                                            <OurTeamBox item={item} key={key}/>
                                        )
                                    })
                                }
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </TeamSection>
    );
}

const Description = styled.div`
    p{
        line-height: 1.5;
        font-size: 1.25rem;
    }
`

const TeamSection = styled.section`
    margin: 5rem 0;
`