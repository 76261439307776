import React, { useEffect, useState, useContext } from "react"
import autobahn from "autobahn"

import { useAppLoaded, useAppMessage } from "./AppLoadedProvider"
import ThankYou from "../components/Utils/ThankYou/ThankYou"
import AppLoaderStatic from "../components/Utils/AppLoader/AppLoaderStatic"

const WSS_URL = process.env.REACT_APP_WSS_URL
const WSS_REALM = process.env.REACT_APP_WSS_REALM

export const EveryMatrixContext = React.createContext(null)

export const EveryMatrixProvider = ({ children }) => {
	
	const socket = new autobahn.Connection({
		url: WSS_URL + (localStorage.getItem('EM_CID') ? '?cid=' + localStorage.getItem('EM_CID') : ''), 
		realm: WSS_REALM,
		max_retries: 3,
		retry_if_unreachable: true
	})

	const [ sessionSocket, setSessionSocket ] = useState(null);
	const { setAppLoaded } = useAppLoaded()
	const { setAppMessage } = useAppMessage()
	const [ canRegister, setCanRegister ] = useState(true) 
	const [ canRegisterLoaded, setCanRegisterLoaded ] = useState(false);



	useEffect(() => {
		
		if(sessionSocket) return

		console.log('Socket is set.')

		socket.onopen = function (session) {

			setSessionSocket(session)

			console.log('Socket opened...')
			session.call("/connection#getClientIdentity").then(
                function (json) {
                    localStorage.setItem('EM_CID', json.kwargs.cid)
					session.call('/user/account#ensureRegistrationIsAllowed').then(
						function(result){
							console.log('Registration is allowed for this user...')
							setCanRegister(true)
							setCanRegisterLoaded(true)
						},
						function(err){
							console.log('Registration is NOT allowed for this user...', err)
							setCanRegister(false)
							setCanRegisterLoaded(true)
						}
					)
                },function (err) {
					console.log('Error in /connection#getClientIdentity:', err)
                }
            )

			session.subscribe("/sessionStateChange", function (uri, data) {
				console.log('/sessionStateChange is fired! code = ' + data.code + ' ; desc = ' + data.desc + ';');
				//logged out sucesfully
				if(data.code!==0){
					window.location.href = '/'
				}
			})

		}

		socket.onclose = function(response){
			console.log('Socket closed...', response)
			localStorage.removeItem('EM_CID')
			setAppLoaded(false)
			setSessionSocket(null)
			if(response==='unreachable'){
				setAppMessage('Sorry for the inconvinience.<br />We are currently experiencing network issues. Please reload the page or check back later.')
			}else{
				window.location.href = '/'
			}
		};
		
		socket.onerror = function(err){ 
			console.log('Socket error...', err)
			localStorage.removeItem('EM_CID')
			setSessionSocket(null)
			window.location.href = '/'
		};

		if(!localStorage.getItem('EM_CID')){
			socket._options.url = WSS_URL
		}
		socket.open()

	/* eslint-disable */
	}, [socket, sessionSocket])
	

	return (	
		
		<EveryMatrixContext.Provider value={sessionSocket}>
			{ canRegister===true && children }
			{ canRegister===false && <ThankYou src='/images/thankyounegative.png'  text='Sorry, we are not available in your country/region right now.' showContact={false} showButtons={false} /> }
			{ !canRegisterLoaded && <AppLoaderStatic /> }
			{/* { canRegisterLoaded ?  ( canRegister ? : ) : ( <AppLoaderStatic/>)} */}
		</EveryMatrixContext.Provider>
	)

}

export const useSessionSocket = () => {
	// get the context
	const context = useContext(EveryMatrixContext);
  
	// if `undefined`, throw an error
	if (context === undefined) {
		throw new Error("useSessionSocket was used outside of its Provider");
	}

  
	return context;
};
