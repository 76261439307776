import React from "react";
import TextInput from '../../Utils/TextInput/TextInput'
//import SelectInput from '../../Utils/SelectInput/SelectInput'
import Passwords from "../../Utils/Passwords/Passwords";
//import { currencies  } from "../../../data/currencies";

export default function RegisterInformation({username, password, confirmPassword, affiliateMarker, currency, securityQuestion, securityAnswer, validatePasswords, setValidatePasswords }){
 
    
    /* const securityQuestionOptions = [
        { value : 'My favourite bet?' , label: "My favourite bet?"},
        { value : 'My favourite colour?' , label: "My favourite colour?"},
        { value : 'My favourite horse?' , label: "My favourite horse?"},
        { value : 'My favourite place?' , label: "My favourite place?"},
        { value : 'My favourite super hero?' , label: "My favourite super hero?"},
        { value : 'My favourite team?' , label: "My favourite team?"},
        { value : 'My middle name?' , label: "My middle name?"},
        { value : "My mother's maiden name?" , label: "My mother's maiden name?"},
        { value : "My pet's name?" , label: "My pet's name?"}
    ]
 */
    return(
        <fieldset>
            <legend>Registration Information</legend>
            
            <TextInput 
                label="Username *"
                type="text"
                value={username.username && username.username} 
                required={true}
                setValue={username.setUsername}
                preventSpaces={true}
                maxLength={50}
            />
                
            <Passwords password={password} confirmPassword={confirmPassword} validatePasswords={validatePasswords} setValidatePasswords={setValidatePasswords} />

            {/* { !affiliateMarker.affiliateMarker && <TextInput 
                label="Promo Code"
                type="text"
                required={false}
                value={affiliateMarker.affiliateMarker && affiliateMarker.affiliateMarker}
                setValue={affiliateMarker.setAffiliateMarker}
                textType="number"
            /> } */}
            
            <TextInput 
                label="Referral code" 
                type="text" 
                inputmode="text"
                value={affiliateMarker.affiliateMarker}
                setValue={affiliateMarker.setAffiliateMarker} 
            />

            {/* <SelectInput label="Security Question *" options={securityQuestionOptions}  setValue={ securityQuestion.setSecurityQuestion } required={true} value={securityQuestion.securityQuestion}  />

            <TextInput 
                label="Answer *"
                type="text"
                required={true}
                value={securityAnswer.securityAnswer && securityAnswer.securityAnswer}
                setValue={securityAnswer.setSecurityAnswer}
            /> */}
            
            {/* <SelectInput label="Currency *" options={currencies}  setValue={ currency.setCurrency } required={true} value={currency.currency}  /> */}
        </fieldset>
    );
}
