import React, { useState, useEffect, useMemo } from "react";
import TextInput from '../../../components/Utils/TextInput/TextInput'
import { locales } from '../../../data/locales'
import SelectInput from '../../../components/Utils/SelectInput/SelectInput'
import { t } from "i18next";

export default function AddressInformation({city, address1, address2, postalCode, country, setCountriesResponse }){
    
    const [ countries, setCountries ] = useState([]);

    const countriesObject = useMemo(() => [], []);
    
    useEffect(() => {
        locales.map((item) => {

            countriesObject.push({
                value: item.code,
                label: t(item.name)
            });

            return null
        });

        setCountries(countriesObject);
        
    },[countriesObject])

    return(
        <fieldset>
            <legend>{ t("Adress Information")}</legend>
            <div className="form-row">
                <TextInput 
                    label="Address 1 *"
                    type="text"
                    required={true}
                    value={address1.address1 && address1.address1}
                    setValue={address1.setAddress1}
                />
            </div>
            <div className="form-row">
                <TextInput 
                    label="Address 2"
                    type="text"
                    required={false}
                    value={address2.address2 && address2.address2}
                    setValue={address2.setAddress2}
                />
            </div>

            <div className="form-row">
                <TextInput 
                    label="City *"
                    type="text"
                    required={true}
                    value={city.city && city.city}
                    setValue={city.setCity}
                />
            </div>
            <div className="form-row">
                <TextInput 
                    label="Postal Code *"
                    type="text"
                    required={true}
                    maxlength={10}
                    value={postalCode.postalCode && postalCode.postalCode}
                    setValue={postalCode.setPostalCode}
                />
            </div> 
            <SelectInput label="Country *" placeholder="Select Country" options={countries}  setValue={country.setCountry } required={true} value={country.country}  errorMessage="Please select your Country" />
        </fieldset>
    );
}