import React, { useCallback, useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components/macro'

import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useUserSession } from '../../../contexts/UserSessionProvider'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'
import Spacer from '../../Utils/Spacer/Spacer'
import { useUserWalletProvider } from '../../../contexts/UserWalletProvider'

import moment from 'moment'
import 'moment-timezone'
import Countdown from 'react-countdown'

const countdownTables = [21]
const formatter = new Intl.NumberFormat('en-US')
const formatPrice = (number, free = false) => {
    return formatter.format((number * 100/ 100).toFixed(2)) + (free ? `<img class="mima-coin" width="15px" alt="MiMa Coin" src="/images/icons/mima-currency-orange.svg" />` : `$`)
}

export default function PublicTables({ currentTable = null }){

    const appOptions = useAppOptions()
    const { lang } = useUserLang()
    const { userSession } = useUserSession()
    const { userWallet } = useUserWalletProvider()

    //const [tableType, setTableType] = useState('all')
    //const [allPokerTables, setAllPokerTables] = useState([])
    const [dealerTables, setDealerTables] = useState([])
    const [onlineTables, setOnlineTables] = useState([])

    const [dealerFreeTables, setDealerFreeTables] = useState([])
    const [onlineFreeTables, setOnlineFreeTables] = useState([])
    
    const dealerPhotos = appOptions && appOptions.acf && appOptions.acf.dealers ? appOptions.acf.dealers : []
    //const excludedTables = appOptions && appOptions.acf && appOptions.acf.excluded_tables ? appOptions.acf.excluded_tables : []
    const bannedPlayers = useMemo(() => {
        return appOptions && appOptions.acf && appOptions.acf.banned_players ? appOptions.acf.banned_players : [];
    }, [appOptions])

	const getPokerTables = useCallback(async () => {
        try {
            if(!userSession) return
            if(!appOptions) return 

            const response = await axios.get(process.env.REACT_APP_WP_API_URL + 'wp/v2/get_poker_tables')
            const allTables = response.data
            const allTablesFiltered = allTables.filter((table) => { 
                return !bannedPlayers.some(player => {
                    return table.table_data.table_id === parseInt(player.table_id) && 
                    userSession.username === player.username
                })
            })

            //setAllPokerTables(allTables)
            setDealerTables(allTablesFiltered.filter((table) => { return !table.table_data.table_auto_mode && !table.table_data.table_demo_mode}))
            setOnlineTables(allTablesFiltered.filter((table) => { return table.table_data.table_auto_mode && !table.table_data.table_demo_mode}))
            
            setDealerFreeTables(allTablesFiltered.filter((table) => { return !table.table_data.table_auto_mode && table.table_data.table_demo_mode}))
            setOnlineFreeTables(allTablesFiltered.filter((table) => { return table.table_data.table_auto_mode && table.table_data.table_demo_mode}))

            console.log(allTablesFiltered)

        } catch (error) {
            console.error('Error fetching poker games:', error)
        }
    }, [userSession, bannedPlayers, appOptions])


    useEffect(() => {
        getPokerTables()
        const interval = setInterval(() => { getPokerTables() }, 30000)
        return () => { clearInterval(interval) }

    }, [getPokerTables])


    if(!appOptions) return <></>

    return (
        <PublicTablesWrap id="public-tables">
            <PublicTableInner>
                <table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th className='desktop'>Name</th>
                            <th>Dealer</th>
                            <th className='desktop'>Stake</th>
                            <th className='desktop'>Buy in</th>
                            <th className='desktop'>Players</th>
                            <th className='mobile'>Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableRLabel>
                            <td className="mobile" colSpan="2">
                                <h4>Real Money Tables</h4>
                            </td>
                            <td className="desktop" colSpan="4">
                                <h4>Real Money Tables</h4>
                            </td>
                            <td colSpan="4">
                                <BalanceLabel>
                                    Real money balance: <span dangerouslySetInnerHTML={{ __html: formatPrice(userWallet.amount) }} />
                                </BalanceLabel>
                            </td>
                        </TableRLabel>
                        { dealerTables && dealerTables.map((table, index) => {
                            return <TableRow 
                                        key={index} 
                                        tableData={table} 
                                        lang={lang} 
                                        iscurrent={currentTable===table.table_id ? 1 : 0} 
                                        dealerPhotos={dealerPhotos} />
                        })}
                        {onlineTables && onlineTables.map((table, index) => {
                            return <TableRow 
                                        key={index} 
                                        tableData={table} 
                                        iscurrent={currentTable===table.table_id ? 1 : 0} 
                                        lang={lang} />
                        })}
                        <TableRLabel>
                            <td className="mobile" colSpan="2">
                                <Spacer />
                                <h4>Free Demo Tables</h4>
                            </td>
                            <td className="desktop" colSpan="4">
                                <Spacer />
                                <h4>Free Demo Tables</h4>
                            </td>
                            <td colSpan="4">
                                <Spacer />
                                <BalanceLabel>
                                    Play Money balance: <span dangerouslySetInnerHTML={{ __html: formatPrice(userWallet.freeAmount, true) }} />
                                </BalanceLabel>
                            </td>
                        </TableRLabel>
                        { dealerFreeTables && dealerFreeTables.map((table, index) => {
                            return <TableRow 
                                        key={index} 
                                        tableData={table} 
                                        lang={lang} 
                                        iscurrent={currentTable===table.table_id ? 1 : 0} 
                                        dealerPhotos={dealerPhotos} />
                        })}
                        {onlineFreeTables && onlineFreeTables.map((table, index) => {
                            return <TableRow 
                                        key={index} 
                                        tableData={table} 
                                        iscurrent={currentTable===table.table_id ? 1 : 0} 
                                        lang={lang} />
                        })}
                    </tbody>
                </table>
            </PublicTableInner>
        </PublicTablesWrap>
    )
}



const TableRow = ({ 
    tableData, 
    dealerPhotos = null, 
    lang = 'en', 
    privateTable = false, 
    iscurrent = false }) => {

    const [todayAtNoonCET, setTodayAtNoonCET] = useState(null)
    const [tableOpen, setTableOpen] = useState(true)
    const free = tableData.table_data.table_demo_mode
    let toLink = privateTable ? '' : (iscurrent===0 ? "/game/live-poker_mima/play"+ (free ? "/free/" : "/") + tableData.table_data.table_game_id : "")
    toLink = lang==='en' ? toLink : "/" + lang + toLink

    let dealerName = null
    let dealerPhoto = null
    if(dealerPhotos){
        dealerName = tableData.table_data.table_dealer_name.replace('_', '')
        dealerPhoto = dealerPhotos.filter((dealer) => {
            return dealer.nickname === dealerName
        })
        dealerPhoto = dealerPhoto && dealerPhoto.length > 0 ? dealerPhoto[0].photo.url : '/images/dealers/no-dealer-photo.png'
    }

    const tableTypeName = dealerPhoto ? t('Live dealer') : t('Virtual table')
    const stakes = formatPrice(tableData.table_data.table_small_blind, free) + " / " + formatPrice(tableData.table_data.table_big_blind, free)
    const buyin = formatPrice(tableData.table_data.table_minimum_buy_in, free) + " / " + formatPrice(tableData.table_data.table_maximum_buy_in, free)
    const players = tableData.table_data.table_live_players + " / " + tableData.table_data.table_max_players



    useEffect(() => {
        const now = moment()
        moment.tz.setDefault('Europe/Paris')
        const isBetween12And20 = now && now.isBetween(now.clone().startOf('day').add(12, 'hours'), now.clone().startOf('day').add(23, 'hours').add(59, 'minutes').add(59, 'seconds'))
        if(countdownTables.includes(tableData.table_id)){
            setTableOpen(isBetween12And20)
        }
        const todayAtNoon = moment().startOf('day').add(12, 'hours')
        setTodayAtNoonCET(todayAtNoon)
    }, [tableData])



    return (
        <TableR 
        iscountdown={!tableOpen && todayAtNoonCET && countdownTables.includes(tableData.table_id) ? '1' : ''}
        isCurrent={iscurrent ? "1" : ""}>
            <td className='desktop'><strong>{ tableData.table_name }</strong></td>
            <td className='desktop'>{ tableTypeName }</td>
            <td className='mobile'>
                <div><strong>{ tableData.table_name }</strong></div>
                <div>{ tableTypeName }</div>
            </td>
            <TDealer dealer={dealerPhoto ? "1" : ""}>
                <ImgWrap dealer={dealerPhoto ? "1" : ""}>
                    { dealerPhoto && (
                        <>
                            <img alt='dealer' src={dealerPhoto} />
                            <DealerName>{ dealerName }</DealerName>
                        </>
                    )}
                    { !dealerPhoto && (
                        <img alt='virtual' src='/images/icons/cards.svg'/>
                    )}
                </ImgWrap>
            </TDealer>
            <TDOrange className='mobile'>
                <div><TableDetailsLabel>{ t('Stake') }:</TableDetailsLabel> <strong dangerouslySetInnerHTML={{ __html: stakes }} />
                </div>
                <div><TableDetailsLabel>{ t('Buy In') }:</TableDetailsLabel> <strong dangerouslySetInnerHTML={{ __html: buyin }} />
                </div>
                <div>
                    <TableDetailsLabel>{ t('Players') }:</TableDetailsLabel> <PlayersActive isActive={tableData.table_data.table_live_players > 0 ? '1' : ''}>{ players }</PlayersActive>
                </div>
            </TDOrange>
            <TDOrange className='desktop' dangerouslySetInnerHTML={{ __html: stakes }} />
            <TDOrange className='desktop' dangerouslySetInnerHTML={{ __html: buyin }} />
            <TDOrange className='desktop'>
                <PlayersActive isActive={tableData.table_data.table_live_players > 0 ? '1' : ''}>{ players }</PlayersActive>
            </TDOrange>
            <td>
                { !iscurrent && <Link to={toLink}>
                    <span className="desktop">{ t('Enter') }</span>
                    <span className="mobile"><img src="/images/icons/icon-play.svg" /></span>
                </Link> }
                { iscurrent!==0 && ( 
                    <span>{ t('Currently playing') }</span>
                )}
            </td>
            { !tableOpen && todayAtNoonCET && countdownTables.includes(tableData.table_id) && (
                <CountdownWrap>
                    <div>Opens in:</div>
                    <Countdown date={todayAtNoonCET.format()} />
                </CountdownWrap>
            )}
        </TableR>
    )
}

const PublicTablesWrap = styled.div `
    position: relative;
    padding: 0rem 0.5rem 1.5rem 1.5rem;
    height: 100%;
    overflow: hidden;
    padding-top: 30px;

    &::after {
        position: absolute;
        display: block;
        content: "";
        height: 95%;
        width: 1px;
        top: 18px;
        right: 29.5px;
        background-color: white;
    }
    
    @media (min-width: 1100px){
        min-height: 450px;
        height: 50vh;
    }
    .mima-coin{
        position: relative;
        bottom: -1px;
        margin-left: 2px;
    }
    
    @media (min-width: 1025px){
        border-radius: 10px;
    }
    
    table {
        border-collapse: collapse;
        width: 98%;

        .mobile {
            @media (min-width: 1100px){
                display: none;
            }
        }

        .desktop {
            display: none;

            @media (min-width: 1100px){
                display: table-cell;
            }
        }

        h4{
            margin: 0;
        }

        th {
            position: sticky;
            top: 0;
            padding: 1rem 0;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase;
            background: #232323b0;
            border-bottom: 1px solid white;
            z-index: 5;
            text-align: left;

            font-size: 0.625rem;
            @media (min-width: 1100px){
                font-size: 0.875rem;
            }
        }

        tr {
            border-bottom: 1px solid #6a6a6a;
        }

        td {
            font-size: 0.625rem;
            @media (min-width: 1100px){
                font-size: 0.875rem;
            }
            &:nth-child(5) {
                text-align: left;
                min-width: 150px;
            }
        }

        strong {
            color: #F49436;
        }
    }
`

const PublicTableInner = styled.div `
    height: 100%;
    overflow-y: scroll;
    margin-right: 17px;
    
    @media (min-width: 1025px){
        max-height: 900px;
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 2px;
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`

const TDOrange = styled.td`
    color: var(--orange);
`

const TableDetailsLabel = styled.span`
    color: white;
`

const TableRLabel = styled.tr`
    td{
        padding-top: 1rem;
        padding-bottom: 0.5rem;
    }
`

const ImgWrap = styled.div`
    max-width: 80px;
    display: flex;
    align-items: center;
    position: relative;
    img {
        max-height: 60px;
        @media (min-width: 1100px){
            max-height: 70px;
        }
        object-fit: contain;
    }
    ${props => props.dealer!=='1' ? css`
        justify-content: center;
        img{
            width: 35%;
            padding: 0.5rem 0;
            padding-top: 0.6rem;
        }
    ` : css`
        margin-top: -6px;
        img{
            width: 100%;
        }
    `}
`

const TDealer = styled.td`
    @media (min-width: 1100px){
        min-width: 95px;
    }
`

const DealerName = styled.div `
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    margin: auto;
    max-width: fit-content;
    background-color: var(--orange-hover);
    border-radius: 6px;
    padding: 1px 6px;
    font-size: 11px;
    color: white;
    @media (min-width: 0px) and (max-width: 767px){
        display: none;
    }
`

const PlayersActive = styled.div`
    display: inline-block;
    padding: 0rem 0.25rem;
    border: 1px solid #000000;
    color: var(--orange);
    @media (min-width: 1100px){
        border-radius: 3rem;
        padding: 0.5rem 1rem;
    }
    ${props => props.isActive==='1' ? css`
        background: #000000;
        border: 1px solid #113b11;
        color: #6bff6b;
    ` : ``}
`

const BalanceLabel = styled.div`
    text-align: right;
    opacity: 0.8;
    span{
        color: var(--orange);
    }
`

const CountdownWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    & > span{
        font-size: 1.25rem;
        font-weight: bold;
        margin: 0 0.5rem;
    }
`


const TableR = styled.tr`
    position: relative;
    ${props => props.isCurrent === "1" ? css`
        background: #515151;
        opacity: 0.7;
    ` : ""};

    ${props => props.iscountdown === "1" ? css`
        background: #171717;
        td{
            filter: blur(5px);
        }
    ` : ""};
`