import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { t } from 'i18next'

export default function MimaGamesSection() {

    const appOptions = useAppOptions()

    return (
        <MimaGamesSectionElement className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-xl-5">
                    <Info>
                        <SectionTitle dangerouslySetInnerHTML={{ __html: appOptions && appOptions.acf && appOptions.acf.mima_games_info.title}} />
                        <SectionSubtitle dangerouslySetInnerHTML={{ __html: appOptions && appOptions.acf && appOptions.acf.mima_games_info.description}} />
                    </Info>
                </div>
                { appOptions && appOptions.acf && appOptions.acf.mima_games && appOptions.acf.mima_games.map((item, index) => {

                    if(!item.active_game) return null

                    return (
                        <div key={index} className="col-xl-4">
                            <GamesRow className="row justify-content-center align-items-center">
                                { item && (
                                    <div className="col-6">
                                        <div className="text-center">
                                            <Link to={'/mima-games/' + item.link}>
                                                <img alt={t(item.name)} src={item.logo.url} />
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </GamesRow>
                        </div>
                    )
                }) }
            </div>
        </MimaGamesSectionElement>
    );
}

const MimaGamesSectionElement = styled.section`
    margin-top: 4rem;
    @media (min-width: 0px) and (max-width: 767px){
        margin-bottom: 3rem;
    }
    img{
        max-width: 300px;
    }
`

const GamesRow = styled.div`
    display: flex;
    align-items: center;
    img{
        transition: all .1s ease-out;
        @media (min-width: 1025px){
            &:hover{
                transform: scale(1.15);
            }
        }
    }
`

const Info = styled.div`
    @media (min-width: 0px) and (max-width: 1199px){
        padding: 0rem 0 2rem 0;
        padding-top: 0;
        text-align: center;
        max-width: 450px;
        margin: 0 auto;
    }
`

const SectionTitle = styled.h1`
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    b{
        color: var(--orange);
    }
`

const SectionSubtitle = styled.h2`
    font-family: var(--main-font), 'Helvetica Neue', Arial, sans-serif;
    margin: 0;
    margin-top: 0.5rem;
    font-weight: 300;
    @media (min-width: 0px) and (max-width: 1199px){
        font-size: 1rem;
        line-height: 1.5rem;
    }
    @media (min-width: 1200px){
        font-size: 1.125rem;
        line-height: 1.85rem;
        max-width: 550px;
    }
`