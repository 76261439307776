import React from 'react'
import { useLocation } from 'react-router-dom'

import Image from '../Image/Image.jsx'
import Button from '../Button/Button.jsx'

import './ThankYou.scss'

import { useDefaultAppBannerStyle } from "../../../contexts/AppOptionsProvider"
import styled from 'styled-components'
import { t } from 'i18next';

const ThankYou = ({src=null, title='', text='', button=null, showContact=true, returnToText= t("Continue to homepage"), returnToLink='/', showButtons=true}) => {

	const {state} = useLocation()
	const inboxUrl = state ? state.inboxUrl : null

	return (
	<div className='thankyou-page' style={useDefaultAppBannerStyle()}>
		<div className="content">
			{ src && <Image src={src} /> }
			{ title && <h3 className="thankyou-title">{title}</h3> }
			{ text && <p className="thankyou-text" dangerouslySetInnerHTML={{ __html: text }}></p> }
			
			{ showContact && <p className="thankyou-trouble">{ t("Having trouble? ")}<a href="mailto:info@mimasupport.com">{ t("Contact us")}</a></p> }
			{ showButtons && 
				<ButtonWrap className='row justify-content-center'>
					{ !button && <Button to={returnToLink}>{returnToText}</Button> }
					{ inboxUrl && <Button to={inboxUrl}>{ t("Check your inbox")}</Button>}
					{ button && button.to && button.label && <Button secondary={inboxUrl.toString()} to={ button.to }>{ button.label }</Button> }
				</ButtonWrap>
			}
		</div>
	</div>
	)
}

export default ThankYou

const ButtonWrap = styled.div`
	.btn{
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
`