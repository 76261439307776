import React from 'react'
import Countdown from 'react-countdown';


const renderer = ({ days, hours, minutes, seconds, completed }) => {

    if (completed) {
      return(
        	<div className="countdown-finished"></div>
      );
    } else {
      return (
        	<span>We are back in: {hours}:{minutes}:{seconds}</span>
      )
    }

    
  };

const CountdownTimer = ({LaunchDate}) => {

    return(
        <Countdown
            date={LaunchDate}
            renderer={renderer}
            zeroPadTime={2}
            zeroPadDays={2}
        >  
        </Countdown>
    );
}

export default CountdownTimer;