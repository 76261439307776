import React from "react";
import TextInput from '../../../components/Utils/TextInput/TextInput'
import PhoneNumberInput from "../../Utils/PhoneNumberInput/PhoneNumberInput";
import { t } from "i18next";

export default function ContactInformation({email, mobile}){
    
    return(
        <fieldset className="Column">
            <legend>{ t("Contact information")}</legend>
            <div className="form-row">
                <TextInput 
                    label="Email *"
                    type="email"
                    inputmode="email"
                    value={email.email && email.email}
                    required={true}
                    setValue={email.setEmail}
                    textType="email"
                />
            </div>
            
            <div className="row">
                <div className="col-12">
                    <PhoneNumberInput 
                        label={ t("Mobile Phone Number *" )}
                        note={ t("Required for activating your account via SMS Code")} 
                        required={true}
                        value={mobile.mobile && mobile.mobile}
                        setValue={mobile.setMobile} />
                </div>
            </div>
        </fieldset>
    );
}