import React from "react"
import { Link } from 'react-router-dom'
import axios from 'axios'
import useGetWPAxios from '../../../../api/useGetWPAxios'
import Spacer from "../../../Utils/Spacer/Spacer"
import { t } from 'i18next'
import { useUserLang } from "../../../../contexts/LanguageSwitcherProvider"

axios.defaults.baseURL = process.env.REACT_APP_API_URL_STAGING

const menuIds = {
	en: 12,
	es: 35,
	ru: 81
}

const GuidesMenu = () => {

	const { lang } = useUserLang()
    const { data } = useGetWPAxios('wp/v2/menu?menu_id=' + menuIds[lang])
    
    return (
		<div className="footer-links-box terms">
			<Spacer />
			<h2>{ t("Guides")}</h2>
			<ul>
				{ data && data.map((item, index) => { 
					return <li key={index}><Link to={(lang !== 'en' ? '/' + lang : '') + item.en} className="link"><span dangerouslySetInnerHTML={{ __html: item.name }} /></Link></li>
					})
				}
			</ul>
		</div>
    )
}

export default GuidesMenu
