import React, { useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAppLoaded } from '../../../contexts/AppLoadedProvider';

import { useUserLogged } from '../../../contexts/UserLoggedProvider';
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider';

export default function ProtectedRoute({ children }){
	
	const location = useLocation()
	const { lang } = useUserLang()
	const navigate = useNavigate()
	const { userLogged } = useUserLogged();
	const { appLoaded } = useAppLoaded()
	
	const loginLink = lang!=='en' ? '/' + lang + '/login' : '/login'

	useEffect(() => {
		if(!appLoaded) return
		if(userLogged) return
		return navigate(loginLink, { state: { returnPath: location.pathname }})
	}, [userLogged, appLoaded, navigate, loginLink, location.pathname])


	return children ? children : <Outlet />;
};