import React , { useState, useRef, lazy, Suspense } from "react";

import styled from 'styled-components'
import Button from '../../Utils/Button/Button'
import ErrorOutput from "../../Output/ErrorOutput/ErrorOutput";
import TextInput from "../../Utils/TextInput/TextInput";
import axios from "axios";
import SuccessOutput from "../../Output/SuccessOutput/SuccessOutput";
import { useLoginPopup } from "../../../contexts/LoginPopupProvider";

import ReCAPTCHA from "react-google-recaptcha";
import { t } from "i18next";
import { useUserLang } from "../../../contexts/LanguageSwitcherProvider";

const SuccessOverlay = lazy(() => import("../../Output/SuccessOverlay"))


export default function SMSActivationModal({ 
    openSMSModal=true, 
    setPassword, 
    mobilePhoneMumber, 
    updateNumber = false,
    email, 
    smsCode, 
    setSmsCode, 
    setAccountSmsActivated, 
    accountSmsActivated, 
    password  }){

    const [ openModal, setOpenModal ] = useState(openSMSModal)
    const [ smsCodeResent, setSmsCodeResent ] = useState(false)
    const [ smsActivating, setSmsActivating ] = useState(false)
    const [ codeActivateError, setCodeActivateError ] = useState(false)

    const { lang } = useUserLang()

    const { setLoginPopup } = useLoginPopup()
    //const { setUserSession } = useUserSession()
    //const { setUserLogged } = useUserLogged()

    const recaptchaRef = useRef(null);
    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();
    const v2Callback = (token, refreshToken) => {
        setRecaptchaResponse(token)
    }


    const handleSmsActivation = (e) => {
        
        if(!smsCode) return
        if(smsCode.length === 0 || smsCode.length<6) return
        if(!mobilePhoneMumber) return

        setSmsCodeResent(null)

        if(!recaptchaResponse && !updateNumber){
            setAccountSmsActivated(false)
            setCodeActivateError(t("Error verifying reCAPTCHA, please try again."))
            return
        }
        
        setSmsActivating(true)

        let numberEndpoint = '/api/v1/engine/auth/v2/activate-sms/'
        let parameters = {
            "mobile_phone_number": mobilePhoneMumber,
            "code": smsCode,
            "recaptcha": recaptchaResponse
        }

        if(updateNumber){
            parameters = {
                "mobile_phone_number": mobilePhoneMumber,
                "code": smsCode,
            }
            numberEndpoint = '/api/v1/engine/accounts/profile/mobile-phone-number/confirm-update/'
        }

        
        axios.post(process.env.REACT_APP_API_URL_STAGING + numberEndpoint, parameters, {
            headers:{
                "Authorization": "JWT " + localStorage.getItem('authJwt')
            }
        }).then((response) => {
            
            setSmsActivating(false)
            setAccountSmsActivated(true)
            setCodeActivateError(false)

            if(updateNumber){
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
                return
            }else{
                setTimeout(() => {
                    setOpenModal(false)
                    setLoginPopup(email)
                }, 600)
            }

            
            /* axios.post(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/auth/v2/login/', {
                "password": password,
                "username": email,
                "recaptcha": recaptchaResponse
            })
            .then((response) => {
                localStorage.setItem('authJwt',response.data.token)

                if(localStorage.getItem('authJwt')){
                    axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/profile-data/',{
                        headers:{
                            "Authorization": "JWT " + localStorage.getItem('authJwt')
                        }
                    }).then((response) => {
                        setUserSession(response.data)
                        setLoginPopup(false)
                        setPassword('')
                        setUserLogged(true)

                        setTimeout(() => {
                            setOpenModal(false)
                        }, 1000)

                    }).catch((error) => {
                        setAccountSmsActivated(false)
                        localStorage.removeItem('authJwt')
                        window.location.reload(false)
                    })
                }

            }); */

        }).catch((error) => {
            recaptchaRef.current?.reset()
            setRecaptchaResponse(null)
            setSmsActivating(false)
            setAccountSmsActivated(false)
            setCodeActivateError(error.response.data.details)
        })

    }

    const resendSmsCode = () => {
        
        setCodeActivateError(false)

        if(!recaptchaResponse){
            setCodeActivateError(t('Error verifying reCAPTCHA, please try again.'))
        }

        const parameters = {
            "mobile_phone_number": mobilePhoneMumber,
            "recaptcha": recaptchaResponse
        }

        axios.post(process.env.REACT_APP_API_URL_STAGING + "/api/v1/engine/auth/v2/resend-code/", parameters).then((response) => {
            setSmsCodeResent(response.data.details)
            recaptchaRef.current?.reset()
            setRecaptchaResponse(null)
        }).catch((error) => {
            recaptchaRef.current?.reset()
            setRecaptchaResponse(null)
            setSmsCodeResent(false)
        })

    }

    return(
        <Suspense fallback={<></>}>
            <SuccessOverlay 
                id="modal-overlay" 
                openModal={openModal} 
                setOpenModal={setOpenModal} 
                setAccountSmsActivated={setAccountSmsActivated}
                //firstButton={{ label: 'Check your inbox', link: email.split('@').length>1 && 'https://' + email.split('@')[1] }}
                firstButton={null}
                secondButton={{ label: t('Continue to Login'), link: '/login' }}>
                    <ModalTitle>{ t("Registered successfully") }!</ModalTitle>
                    <ModalContent>
                        <SmsCodeActivation>
                            <p>{ t("To activate and verify your account, please enter the SMS code sent to") } <br /><HighlightNumber>{ mobilePhoneMumber }</HighlightNumber></p>
                            <SmsCodeForm>
                                <TextInput textType="number" minlength={6} maxlength={6} type="text" inputmode="numeric" label="" value={smsCode} setValue={setSmsCode} />
                                <Button 
                                    disabled={smsActivating} 
                                    onClickEvent={handleSmsActivation}>{ t("Activate SMS") }</Button>
                            </SmsCodeForm>

                            <div id="recaptcha-badge">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={sitekey}
                                    theme={'light'}
                                    hl={lang}
                                    onChange={v2Callback}
                                    tabindex={0}
                                />
                            </div>

                            { !accountSmsActivated && <SmsResend><a href="/" onClick={(e) => { e.preventDefault(); resendSmsCode(); }}>{ t("Resend SMS Code") }</a></SmsResend> }


                            { smsCodeResent && <SuccessOutput>{ smsCodeResent }</SuccessOutput> }
                            { codeActivateError && <ErrorOutput>{ codeActivateError }</ErrorOutput> }
                            { accountSmsActivated && !updateNumber && <SuccessOutput>{ t("Account verified by SMS successfully") }!</SuccessOutput> }
                            { accountSmsActivated && updateNumber && <SuccessOutput>{ t("Phone number verified successfully") }!</SuccessOutput> }

                            <SupportNote>{ t("If you believe you have entered the wrong number, please reach out to us via Live Chat located at the bottom right corner of the website. Our agents will be happy to assist you.") }</SupportNote>

                        </SmsCodeActivation>
                        
                        {/* { !updateNumber && <p>You will also receive a verification email to activate your account. Please check your inbox.</p> } */}
                        
                    </ModalContent>
            </SuccessOverlay>
        </Suspense>
    )
}

const ModalTitle = styled.h4`
    margin: 0;
    font-size: 1.5rem;
`

const ModalContent = styled.div`
    p{
        margin: 0.5rem 0;
    }
`

const SmsCodeActivation = styled.div`
    #recaptcha-badge{
        display: flex;
        justify-content: center;
        text-align: center;
    }
`

const SmsCodeForm = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    .form-row{
        input[type=text]{
            font-family: monospace;
            padding: 0.6rem 1rem;
            font-size: 1.5rem;
            font-weight: bold;
            max-width: 150px;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
            border: 1px solid gray;
            background: white;
            color: black;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
`

const SmsResend = styled.div`
    margin-top: 0.5rem;
    a{
        font-size: 0.875rem;
    }
`

const HighlightNumber = styled.span`
    color: var(--orange);
    font-weight: bold;
`

const SupportNote = styled.div`
    font-size: 0.825rem;
    line-height: 1rem;
    background: #f1ffb0;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
`