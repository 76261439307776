import React from 'react'
import styled, { css } from 'styled-components'
import { isWebpSupported } from 'react-image-webp/dist/utils'

import Button from '../../Utils/Button/Button'

export default function DetailedFancyInfoSection({ contentBoxes }) {

    return (
        <InfoSection>
            <div className="container">
                <div className='row justify-content-center align-items-center'>
                    {
                        contentBoxes && contentBoxes.map((item, index) => {
                            return (
                                <InfoBox key={index} className="col-xl-8">
                                    <InfoRow>
                                        <InfoBgMask colorTheme={item.color_theme}>
                                            <InfoBg colorTheme={item.color_theme} src={item.background_image.sizes['large']} />
                                        </InfoBgMask>
                                        <InfoTextCol>
                                            <InfoTextColAlign>
                                                <InfoTitle dangerouslySetInnerHTML={{ __html: item.title }} />
                                                <InfoSubtitle dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                                                {
                                                    item.button.label && <Button colorTheme={item.color_theme}  to={item.button.links_to}>{item.button.label}</Button>
                                                }
                                            </InfoTextColAlign>
                                        </InfoTextCol>
                                        <InfoImage>
                                            <IsolatedImage width={item.isolated_image.width} height={item.isolated_image.height} alt={item.title} src={ item.isolated_image.url + (isWebpSupported ? '.webp' : '') } />
                                        </InfoImage>
                                    </InfoRow>
                            </InfoBox>
                            )
                        })
                    }
                </div>
            </div>
        </InfoSection>
    )
}




const InfoSection = styled.section`
    margin-bottom: 2rem;
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 3rem;
    }
    @media (min-width: 768px){
        margin-top: 7rem;
    }
`

const InfoRow = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 1rem;
    align-items: flex-end;
    padding: 0 3rem;
    margin-bottom: 2rem;
    @media (min-width: 768px){
        margin-bottom: 8rem;
    }
    @media (min-width: 1200px){
        margin-bottom: 5rem;
        padding: 0 5rem;
    }
`

const InfoBgMask = styled.div`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 2rem;
    ${props => props.colorTheme ? `
        border: 6px solid var(--${props.colorTheme});
    ` : ``}
`

const InfoBg = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    margin: auto;
    transform: translateX(-50%) translateY(-50%);
    ${props => props.src ? css`background-image: url('${props.src}')` : ``};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    filter: blur(15px);
    z-index: 1;
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${props => props.colorTheme ? `
            background: var(--${props.colorTheme}-dark);
        ` : ``}
        opacity: 0.8;
    }
`

const infoStyle = css`
    position: relative;
    z-index: 15;
`

const InfoTextCol = styled.div`
    ${infoStyle}
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 768px){
        padding: 4rem 0;
        flex: 0 0 65%;
        width: 65%;
    }
    @media (min-width: 1200px){
        min-height: 350px;
    }
`

const InfoTextColAlign = styled.div``

const InfoImage = styled.div`
    ${infoStyle}
    @media (min-width: 0px) and (max-width: 767px){
        display: flex;
        justify-content: center;
    }
    @media (min-width: 768px){
        flex: 0 0 33%;
        width: 33%;
        position: absolute;
        bottom: 0;
    }
`

const IsolatedImage = styled.img`
    height: auto;
    float: left;
    margin: 0 auto;
    margin-top: -2rem;
    width: 60%;
    object-fit: contain;
    @media (min-width: 768px){
        width: 100%;
        margin-top: -10rem;
        max-height: 380px;
    }
`

const InfoTitle = styled.h3`
    margin: 0;
    font-size: 2rem;
`
const InfoSubtitle = styled.p`
    margin-bottom: 1.5rem;
    @media (min-width: 768px){
        max-width: 80%;
    }
`

const InfoBox = styled.div`
    &:nth-child(even){
        & > div{
            flex-direction: row-reverse;
            
            @media (min-width: 768px){
                ${InfoTextCol}{
                    padding-left: 4rem;
                    flex: 0 0 67%;
                    width: 67%;
                }
                ${InfoSubtitle}{
                    max-width: 90%;
                }
                ${InfoImage}{
                    left: 7%;
                }
            }
        }
    }
    &:nth-child(odd){
        & > div{
            @media (min-width: 768px){
                ${InfoImage}{
                    right: 7%;
                }
            }
        }
    }
    @media (min-width: 1200px){
        &:nth-child(odd){
            & > div{
                margin-right: -4rem;
            }
        }
        &:nth-child(even){
            & > div{
                margin-left: -4rem;
            }
        }
    }
`