import React, { useState } from "react";
import { useSessionSocket } from "../../../contexts/EveryMatrixProvider";
import { useDefaultAppBannerStyle } from "../../../contexts/AppOptionsProvider";
import TextInput from "../TextInput/TextInput";
import {
    EReCaptchaV2Size,
    EReCaptchaV2Theme,
    ReCaptchaProvider,
    ReCaptchaV2,
} from 'react-recaptcha-x';

import SubmitButton from "../SubmitButton/SubmitButton";
import ErrorOutput from "../../Output/ErrorOutput/ErrorOutput";
import SuccessOutput from "../../Output/SuccessOutput/SuccessOutput";
import { t } from "i18next";

export default function ChangePasswordPopupForm({ usernameOrEmail }){

    
    const sessionSocket = useSessionSocket()

    const [ errorResponse, setErrorResponse ] = useState(false);
    const [ successfullResponse,setSuccessfullResponse ] = useState('');
    const [ response, setResponse ] = useState(false);

    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();

    const v2Callback = (token, refreshToken) => {
        setRecaptchaResponse(token)
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if(!recaptchaResponse){
            setResponse(true)
            setErrorResponse( t("Please complete the captcha")); 
        }

        var parameters = {
            email: usernameOrEmail,
            changePwdURL: process.env.REACT_APP_URL + 'reset-password?key=',
            captchaPublicKey: sitekey,
            captchaResponse: recaptchaResponse
        };

        sessionSocket.call("/user/pwd#sendResetPwdEmail", [], parameters).then(
            function (result) {
            setSuccessfullResponse( t("An email has been sent to your mailbox, please click the link in the email to change your password."));
        }
        , function (err) {
            setErrorResponse(err.kwargs.desc);
        }
        );
    }

    return(
        <div className="login-container form-section">
            <div className='login-popup' id='login-popup'>
                <div className='login-image' 
                    style={useDefaultAppBannerStyle()}
                >
                </div>
                <div className="login-wrapper">
                    <h1>{ t("Change Password")}</h1>  
                    <form onSubmit={handleSubmit}>
                        <TextInput 
                            className="disabled-style"
                            label={ t("E-mail address")}
                            type="text"
                            required={true}
                            value={usernameOrEmail && usernameOrEmail}
                            autoFocus={true}
                            readonly={true}
                        />
                       
                        <div className='form-input-box mt-2'>
                            <div className='form-input'>
                                <ReCaptchaProvider
                                    siteKeyV2={sitekey}
                                    langCode="en"
                                    hideV3Badge={false}
                                    >
                                    <ReCaptchaV2
                                        callback={v2Callback}
                                        theme={EReCaptchaV2Theme.Dark}
                                        size={EReCaptchaV2Size.Invisible}
                                        id="recaptcha-badge"
                                        tabindex={0}
                                    />
                                </ReCaptchaProvider>
                            </div>
                        </div>
                        <SubmitButton theme="light">{ t("Change Password")}</SubmitButton>

                        
                        
                        { errorResponse && <ErrorOutput>{ errorResponse }</ErrorOutput> }
                        { response && !errorResponse && <SuccessOutput >{ successfullResponse }</SuccessOutput> }
                        
                    </form>
                </div>
            </div>
        </div>
    );
}