import React from "react"
import './TermsMenu.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import useGetWPAxios from '../../../../api/useGetWPAxios'
import { t } from 'i18next'
import { useUserLang } from "../../../../contexts/LanguageSwitcherProvider"

axios.defaults.baseURL = process.env.REACT_APP_API_URL_STAGING

const menuIds = {
	en: 8,
	es: 86,
	ru: 100
}

const TermsMenu = () => {

	const { lang } = useUserLang()
    const { data } = useGetWPAxios('wp/v2/menu?menu_id=' + menuIds[lang])
    
    return (
		<div className="footer-links-box terms">
			<h2>{ t("Security & Privacy")}</h2>
			<ul>
				{ data && data.map((item, index) => { 
					return <li key={index}><Link to={(lang !== 'en' ? '/' + lang : '') + item.en} className="link">{( t(item.name))}</Link></li>
					})
				}
			</ul>
		</div>
    )
}

export default TermsMenu
