import React, { useState } from "react"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Lazy, Autoplay, Pagination, EffectFade } from 'swiper'
import 'react-modal-video/scss/modal-video.scss'
import ModalVideo from 'react-modal-video'
import styled from "styled-components"
import useGetWPAxios from "../../../api/useGetWPAxios"

import 'swiper/swiper-bundle.css'

const InstroductionSlider = ( {video} ) => {

    const { data } = useGetWPAxios('wp/v2/instructional_video?_embed')

    const [openVideo, setOpenVideo] = useState(false)
    return (
		<>
			<Introduction>
				<h2>How to Videos</h2>
				<p>Watch our quick videos to learn how to use the platform</p>
				<Swiper className="hero-promo-slider"
				breakpoints={{
					0: { slidesPerView: 1, spaceBetween: 20 },
					768: { slidesPerView: 2, spaceBetween: 10 },
					1420: {slidesPerView: 3, spaceBetween: 10},
				}}
				pagination={{
					clickable: true,
				}}
				loop={true} 
				center={"true"}
				modules={[Lazy, Autoplay, Pagination, EffectFade]}
				speed={1000}
				autoplay={{ delay: 6000 }} 
				preloadImages={true} 
				lazy={{
					loadPrevNext: true,
					loadPrevNextAmount: 0
				}}>
					{ data && data.map((slide, index) => {
						return (
							<SwiperSlide key={index}>
								<img onClick={()=> setOpenVideo(slide.acf.video_url)} 
								className="sliderimg" 
								src={ slide._embedded['wp:featuredmedia'][0].source_url } alt={ slide.title.rendered }/>
							</SwiperSlide>
						)
					})}
				</Swiper>
			</Introduction>
			{ openVideo && (
				<ModalVideo 
					channel='youtube' 
					autoplay 
					isOpen={openVideo !== false} 
					videoId={openVideo}
					onClose={() => setOpenVideo(false)} 
				/>
			)}
		</>
    )


}

export default InstroductionSlider;

const Introduction = styled.div`
	text-align: center;
	padding: 3rem 0;
	background: #00000099;
	box-shadow: 0px -5px 30px #361b00;
	border-top: 1px solid var(--orange-hover);
	border-bottom: 1px solid var(--orange-hover);
	h2{
		font-size: 2rem;
		margin-bottom: 0;
	}
	p{
		margin-top: 0.5rem;
	}
	.hero-promo-slider{
		padding: 2rem 1rem;
		padding-top: 0;
		padding-left: 1.5rem;
		@media (min-width: 1024px){
			padding: 3rem;
			padding-top: 0;
			padding-left: 4.5rem;
		}
		.sliderimg {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			width: 500px;
			height: 250px;
			border: 5px solid #555;
			border-radius: 15px;
			object-fit: cover;
			cursor: pointer;
			@media (min-width: 0px) and (max-width: 767px){
				width: 100%;
				height: 250px;
				margin: auto;			
			}
			@media (min-width: 1024px){
				transition: all .2s ease-out;
				&:hover{
					transform: scale(1.005);
					box-shadow: 0px 10px 15px #333;
					filter: brightness(1.5);
				}
			}
		}
		.swiper-pagination{
			.swiper-pagination-bullet{
				background: white;
				width: 0.75rem;
				height: 0.75rem;
			}
		}
	}
`
