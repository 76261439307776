import React from 'react'
import styled from 'styled-components/macro'
import { t } from 'i18next';

export default function Invitations(){

    return (
        <InvitationsWrap id="invitations">
            <h2>Invitation</h2>
            <InvitationsInner>
                <InvitationSingle>
                    <MobileType>
                        <strong>TABLE</strong>
                        <div><span>Club:</span> MiMa Poker</div>
                    </MobileType>
                    <Type>Table Invitation</Type>
                    <Club><strong>Club: </strong> Goce's Masters</Club>
                    <Dealer><strong>Dealer: </strong> Sophia</Dealer>
                    <Date><strong>Date/Time: </strong> 15.05.2024 / 20:00</Date>
                    <MobileInfo>
                        <div><span>Dealer: </span> Sophia</div>
                        <div><span>Date: </span>15.05.2024</div>
                        <div><span>Time: </span>20:00</div>
                    </MobileInfo>
                    <ButtonsWrap>
                        <button><img src='images/icons/accept.svg' alt='accept' /></button>
                        <button><img src='images/icons/decline.svg' alt='decline' /></button>
                    </ButtonsWrap>
                </InvitationSingle>
                <InvitationSingle>
                    <MobileType>
                        <strong>TABLE</strong>
                        <div><span>Club:</span> Goce Poker</div>
                    </MobileType>
                    <Type>Table Invitation</Type>
                    <Club><strong>Club: </strong> Goce's Masters</Club>
                    <Dealer><strong>Dealer: </strong> Sophia</Dealer>
                    <Date><strong>Date/Time: </strong> 15.05.2024 / 20:00</Date>
                    <MobileInfo>
                        <div><span>Dealer: </span> Sophia</div>
                        <div><span>Date: </span>15.05.2024</div>
                        <div><span>Time: </span>20:00</div>
                    </MobileInfo>
                    <ButtonsWrap>
                        <button><img src='images/icons/accept.svg' alt='accept' /></button>
                        <button><img src='images/icons/decline.svg' alt='decline' /></button>
                    </ButtonsWrap>
                </InvitationSingle>
                <InvitationSingle>
                    <MobileType>
                        <strong>Club</strong>
                    </MobileType>
                    <Type>Club Invitation</Type>
                    <Club>Brajko's club</Club>
                    <ButtonsWrap>
                        <button><img src='images/icons/accept.svg' alt='accept' /></button>
                        <button><img src='images/icons/decline.svg' alt='decline' /></button>
                    </ButtonsWrap>
                </InvitationSingle>
                <InvitationSingle>
                    <MobileType>
                        <strong>Club</strong>
                    </MobileType>
                    <Type>Club Invitation</Type>
                    <Club>Alex's club</Club>
                    <ButtonsWrap>
                        <button><img src='images/icons/accept.svg' alt='accept' /></button>
                        <button><img src='images/icons/decline.svg' alt='decline' /></button>
                    </ButtonsWrap>
                </InvitationSingle>
                <InvitationSingle>
                    <MobileType>
                        <strong>Club</strong>
                    </MobileType>
                    <Type>Club Invitation</Type>
                    <Club>Nicklas's club</Club>
                    <ButtonsWrap>
                        <button><img src='images/icons/accept.svg' alt='accept' /></button>
                        <button><img src='images/icons/decline.svg' alt='decline' /></button>
                    </ButtonsWrap>
                </InvitationSingle>
            </InvitationsInner>
        </InvitationsWrap>
    )
}

const InvitationsWrap = styled.div `
    padding: 25px;
    position: relative;

    &::after {
        position: absolute;
        display: block;
        content: "";
        height: 75%;
        width: 1px;
        top: 73px;
        right: 29.5px;
        background-color: white;
    }

    h2 {
        font-size: 20px;
        font-weight: bold;
        color: white;
    }
`

const InvitationsInner = styled.div `
    max-height: 250px;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 2px;
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`

const InvitationSingle = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    border: 2px solid white;
    border-radius: 35px;
    padding: 7px 9px;
    font-size: 22px;
    margin-bottom: 12px;

    &:first-child {
        border-radius: 0 35px 35px 35px;
    }

    >div {
        flex: auto;
    }

    strong {
        color: #f49436;
    }
`

const Type = styled.div `
    text-transform: uppercase;
    font-size: 24px;
    display: none;

    @media (min-width: 1025px){
        display: block;
        color: white
    }
`
const Club = styled.div `
    color: #f49436;
    font-weight: bold;

    @media (min-width: 1025px){
        color: white;
    }
`
const Dealer = styled.div `
    display: none;
    
    @media (min-width: 1025px){
        display: block;
    }
`
const Date = styled.div `
    display: none;

    @media (min-width: 1025px){
        display: block;
    }
`

const MobileType = styled.div `
    display: flex;
    flex-direction: column;

    span {
        color: #f49436;
    }

    strong {
        color: white;
    }

    @media (min-width: 1025px){
        display: none;
    }
`

const MobileInfo = styled.div `
    display: flex;
    flex-direction: column;

    span {
        color: #f49436;
    }

    @media (min-width: 1025px){
        display: none;
    }
`

const ButtonsWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        height: 54px;
        width: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 10px;
        border: none;
        background-color: #4c4c4c;
        cursor: pointer;

        &:hover {
            background-color: #f69534;
        }
    }
`