import React, { useEffect, useState } from 'react';

import { useAppLoaded, useAppMessage } from '../../../contexts/AppLoadedProvider';

import styled from 'styled-components'

import MimaLoader from './MimaLoader';

export default function AppLoader({ loadit=false, children }){

    const { appLoaded } = useAppLoaded();
    const { appMessage } = useAppMessage();
    const [ tmpLoaded, setTmpLoaded ] = useState(false)

    useEffect(() => {
        if(appLoaded){
            setTimeout(() => {
                setTmpLoaded(true)
            }, 2000)
        }
    }, [appLoaded, setTmpLoaded])

    if(loadit || (!tmpLoaded || !appLoaded)){
        return (
            <AppLoaderWrap>
                <MimaLoader />
                { appMessage && <AppNotice dangerouslySetInnerHTML={{ __html: appMessage }} className="app-notice"></AppNotice> }
            </AppLoaderWrap>
        )
    }else{
        return ( appLoaded && <></> )
    }
}



const AppLoaderWrap = styled.div`
    position: fixed;
    z-index: 999999;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 200px;
        height: auto;
    }
    h3{
        margin-top: 20px;
        font-size: 16px;
    }
`

const AppNotice = styled.div`
    text-align: center;
    margin-left: -15px;
    padding: 25px;
    line-height: 24px;
    background: rgba(85, 57, 3, 0.9);
    border-radius: 15px;
`