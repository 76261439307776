import React, { useState } from "react"

export const UserLoggedContext = React.createContext({
	userLogged: false,
	setUserLogged: () => {}
})

export const UserLoggedProvider = ({ children }) => {

	const [userLogged, setUserLogged] = useState(false);

	return (
		<UserLoggedContext.Provider value={{ userLogged, setUserLogged }}>
			{ children }
		</UserLoggedContext.Provider>
	)

}

export const useUserLogged = () => {

	const { userLogged, setUserLogged } = React.useContext(UserLoggedContext)

	if(userLogged === undefined && setUserLogged === undefined ){
		throw new Error('useUserLogged was used outside its Provider!')
	}

	return { userLogged, setUserLogged }

}