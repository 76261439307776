import React, { useState, useMemo, useEffect, useRef } from "react"
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'

import moment from "moment-timezone"

import ErrorOutput from '../../Output/ErrorOutput/ErrorOutput';
import SuccessOutput from '../../Output/SuccessOutput/SuccessOutput';
import LoadingOutput from '../../Output/LoadingOutput/LoadingOutput';
import PersinalInformation from "./PersonalInformation";
import ContactInformation from "./ContactInformation";
import AddressInformation from "./AddressInformation";
import RegisterInformation from "./RegisterInformation";
import Cookies from 'universal-cookie'
import SMSActivationModal from "../SMSActivationModal/SMSActivationModal";

import CheckboxButton from '../../Utils/CheckboxButton/CheckboxButton'

import ReCAPTCHA from "react-google-recaptcha";
import { useUserLang } from "../../../contexts/LanguageSwitcherProvider";


import './RegisterForm.scss'
import Button from "../../Utils/Button/Button";
import { useAppOptions } from "../../../contexts/AppOptionsProvider";
import { useUserAffiliateMarker } from "../../../contexts/UserSessionProvider";
import axios from "axios";
import { t } from "i18next";

export default function RegisterForm(){
    

    const cookies =  useMemo(() => new Cookies(), []);

    const { lang } = useUserLang()

    const appOptions = useAppOptions()

    const recaptchaRef = useRef(null);
    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();
    const v2Callback = (token, refreshToken) => {
        console.log(token);
        setRecaptchaResponse(token)
    };

    const { affiliateMarker, setAffiliateMarker } = useUserAffiliateMarker()


    const [ smsCode, setSmsCode ]  = useState(null)
    const [ accountSmsActivated, setAccountSmsActivated ] = useState(null)

    /* Personal Information */
    const [ firstname, setFirstname] = useState('');
    const [ surname, setSurname] = useState('');
    const [ title, setTitle] = useState('');
    const [ gender, setGender] = useState('');
    const [ birthName, setBirthName] = useState('');
    const [ birthDay, setBirthDay] = useState('');
    const [ birthMonth, setBirthMonth] = useState('');
    const [ birthYear, setBirthYear] = useState('');

    /* Contact Information */
    const [ email, setEmail ] = useState('')
    const [ mobile, setMobile] = useState('')

    /* Address information */
    const [ city, setCity] = useState('');
    const [ address1, setAddress1] = useState('');
    const [ address2, setAddress2] = useState('');
    const [ postalCode, setPostalCode] = useState('');

    /* Login information */
    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ confirmPassword, setConfirmPassword ] = useState('')
/*     const [ securityQuestion, setSecurityQuestion ] = useState('')
    const [ securityAnswer, setSecurityAnswer ] = useState('') */


    const [ errorResponse, setErrorResponse] = useState(null);
    const [ responseState, setResponseState ] = useState(null);
    const [ isLoading, setIsLoading] = useState(false);

    const [ currency, setCurrency ] = useState("USD")
    const [ country, setCountry ] = useState(null)
    const [ countriesResponse, setCountriesResponse ] = useState(null)
    
    const [ termsConditions, setTermsConditions ] = useState(null);
    const [ yearCheck, setYearCheck] = useState(null);
    const [ subscribeCheck, setSubscribeCheck] = useState(false);

    const [ requiredCheck, setRequiredCheck ] = useState(false);
    
    const [ validatePasswords, setValidatePasswords ] = useState(false);

    const requiredFields = [
        { value: firstname , setValue: setFirstname },
        { value: surname , setValue: setSurname },
        { value: title , setValue: setTitle },
        { value: gender , setValue: setGender },
        { value: birthDay , setValue: setBirthDay },
        { value: birthMonth , setValue: setBirthMonth },
        { value: birthYear , setValue: setBirthYear },
        { value: email , setValue: setEmail },
        { value: mobile , setValue: setMobile },
        { value: city , setValue: setCity },
        { value: address1 , setValue: setAddress1 },
        { value: postalCode , setValue: setPostalCode },
        { value: username , setValue: setUsername },
        { value: password , setValue: setPassword },
        { value: confirmPassword , setValue: setConfirmPassword },
        /* { value: securityQuestion , setValue: setSecurityQuestion },
        { value: securityAnswer , setValue: setSecurityAnswer }, */
        { value: currency , setValue: setCurrency },
        { value: country , setValue: setCountry },
    ];
    

    const downloadPdfFile = (e) => {
        e.preventDefault()
        console.log(e.target.title)
        const fileURL = e.target.href
        fetch(fileURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
            new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                e.target.title,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    

    function underAgeValidate(birthYear, birthMonth, birthDay){

        let myBirthday =  moment.tz(birthYear + '-' + ("0" + birthMonth).slice(-2) + '-' + ("0" + birthDay).slice(-2) + 'T00:00', "America/New_York").unix();
        var currentDate = moment().unix();
        var myAge = parseInt((currentDate - myBirthday) / (31557600));

        if(myAge < 18) {
            return false;
        }else{
            return true;
        }

    } 

    function getQueryString(name){
        name = name.replace("/[]/", "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }



    useEffect(() => {
        if(getQueryString('affcode') !== ''){
            setAffiliateMarker(getQueryString('affcode'));
            
        }
    }, [setAffiliateMarker])

    function validatePassword(pass){
        let testStr = "^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$"

        if(!pass) return false

        if(pass.match(testStr)){
            return true
        }
        return false;
    }

    const validateEmail = (email) => {
        return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }
    
    const handleRegisterSubmit = async e => {
        e.preventDefault();


        setErrorResponse(""); 
        setRequiredCheck(false);
        setValidatePasswords(true);
      
        let birthDateFormated = birthYear + "-" + ("0" + birthMonth).slice(-2) + "-" +  ("0" + birthDay).slice(-2);
        let requiredFieldsMissing = false;
        
        setErrorResponse(null)
        setRequiredCheck(false)

        if(!validateEmail(email)){
            setErrorResponse("You have entered an invalid email address!")
            requiredFieldsMissing = true
        }

        
        if(yearCheck === false || yearCheck === null){
            setYearCheck(false);
            setErrorResponse("Please confirm that you are 18 years"); 
            setRequiredCheck(true);
            requiredFieldsMissing = true
        }
        

        if(termsConditions === false || termsConditions === null){
            setTermsConditions(false);
            setErrorResponse("Please Accept Terms and Conditions"); 
            setRequiredCheck(true);
            requiredFieldsMissing = true
        }
        
        requiredFields.map((item) => {
            
            if(item.value === ''){
                setRequiredCheck(true);
                setErrorResponse("Please fill in all the required fields!");
                requiredFieldsMissing = true
                item.setValue(null);
            }
            return null;
        })
        
        if(requiredFieldsMissing === true) { return }
        
        if(password !== confirmPassword){
            setErrorResponse("Passwords doesnt match!");
            setRequiredCheck(true);
            requiredFieldsMissing = true
            return null;
        }

        if(validatePassword(password) === false){
            setErrorResponse("Password must be at least 8 characters long contain a number and an uppercase letter");
            setRequiredCheck(true);
            requiredFieldsMissing = true
            return null;
        }

        if(mobile.length < 6){
            setErrorResponse('Mobile length must be between 6 and 30');
            setRequiredCheck(true);
            requiredFieldsMissing = true
            return null;
        }

        if(underAgeValidate(birthYear, birthMonth, birthDay) === false){
            setErrorResponse("Age must be over 18 years old"); 
            setRequiredCheck(true);
            requiredFieldsMissing = true
            return null;
        }

        if(yearCheck === false || yearCheck === null){
            setYearCheck(false);
            setErrorResponse("Please confirm that you are 18 years"); 
            setRequiredCheck(true);
            requiredFieldsMissing = true
            return null;

        }

        if(!recaptchaResponse){
            setErrorResponse("Please complete the reCaptcha process."); 
        }
        
        
        if(requiredCheck === false && requiredFieldsMissing === false && recaptchaResponse){
            

            let parameters = {
                "email":email,
                "username":username,
                "alias":username,
                "password":password,
                "password2":confirmPassword,
                "primary_currency":currency,
                "country":country,
                "title":title,
                "mobile_phone_number":mobile,
                "city":city,
                "address_line_1":address1,
                "address_line_2":address2,
                "zip_code":postalCode,
                "first_name":firstname,
                "last_name":surname,
                "birthName":birthName,
                "date_of_birth":birthDateFormated,
                "affiliateMarker":affiliateMarker,
                "terms_v1_opt_in": termsConditions,
                "terms_v2_opt_in": yearCheck,
                "terms_v3_opt_in": subscribeCheck,
                "recaptcha": recaptchaResponse
            }

            if(getQueryString('affcode') !== ''){
                parameters['affcode'] = getQueryString('affcode');
            }

            if(cookies.get('affcode') !== undefined){
                parameters['affcode'] = cookies.get('affcode');
            }

            if(affiliateMarker){
                parameters['affcode'] = affiliateMarker;
            }

            setIsLoading(true)
        

            //setAccountSmsActivated(false);
            setResponseState(true);
            

            axios.post(process.env.REACT_APP_API_URL_STAGING + "/api/v1/engine/auth/v2/sign-up/",parameters)
            .then((response) => {
                setAccountSmsActivated(false);
                setResponseState(true);
                setErrorResponse(false);

                ReactGA.event({
                    category: 'User',
                    action: 'Register',
                    label: 'Full Registration'
                })

                ReactPixel.trackCustom('Full Registration', { username: username })

                if(parameters['affcode'] !== undefined){
                    axios.post(process.env.REACT_APP_AFF_REGISTER,parameters).then((response) => console.log(response))
                }
            })
            .catch(function (error) {
                recaptchaRef.current?.reset();
                setErrorResponse(error.response.data.details);
                setIsLoading(false);
                setAccountSmsActivated(true);
            });
        }

    }
    
    return (
        <section className="form-section">
                <form onSubmit={handleRegisterSubmit} name="RegisterForm" id="RegisterForm" noValidate >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-11 col-xs-10 col-xl-5">
                                <h1>Register Here</h1>

                                <h3>Personal Information</h3>
                                <PersinalInformation 
                                    title={{title,setTitle}} 
                                    firstname={{firstname,setFirstname}}  
                                    surname={{surname,setSurname}}
                                    gender={{ gender,setGender }}
                                    birthName={{birthName,setBirthName}}
                                    birthDay={{birthDay,setBirthDay}}
                                    birthMonth={{birthMonth,setBirthMonth}} 
                                    birthYear={{birthYear,setBirthYear}}
                                />

                                <h3>Contact Information</h3>
                                <ContactInformation 
                                    email={{email,setEmail}} 
                                    mobile={{mobile,setMobile}}
                                />

                                <h3>Address Information</h3>
                                <AddressInformation 
                                    city={{city,setCity}}
                                    address1={{address1,setAddress1}} 
                                    address2={{address2,setAddress2}}
                                    postalCode={{postalCode,setPostalCode}} 
                                    country={{country,setCountry}}
                                    setCountriesResponse={setCountriesResponse}
                                    countriesResponse={countriesResponse}
                                />

                                <h3>Register Information</h3>
                                <RegisterInformation
                                    username={{username,setUsername}}
                                    password={{password,setPassword}}
                                    confirmPassword={{confirmPassword,setConfirmPassword}}
                                    //currency={{currency,setCurrency}}
                                    affiliateMarker={{affiliateMarker,setAffiliateMarker}}
                                    /* securityQuestion={{securityQuestion,setSecurityQuestion}}
                                    securityAnswer={{securityAnswer,setSecurityAnswer}} */
                                    validatePasswords={validatePasswords}
                                    setValidatePasswords={setValidatePasswords}
                                />
                            

                                <fieldset className="register-checkboxes">

                                    <CheckboxButton className={termsConditions === false ? 'required-field' : ''} setValue={setTermsConditions}>
                                        { 
                                            appOptions && appOptions.acf && appOptions.acf.terms_and_conditions_pdf && (
                                                <>
                                                    { t('I confirm that I ') }<a onClick={downloadPdfFile} download target="_blank" rel="noreferrer" className="terms" href={ appOptions.acf.terms_and_conditions_pdf.url } title={ appOptions.acf.terms_and_conditions_pdf.filename } >{ t('downloaded')}</a> { t('and')} <a rel="noreferrer" target="_blank" className="terms" href="/security-privacy/terms-conditions/">{ t('read')}</a>{ t(' the Terms ')}&amp; { t('Conditions')}. *
                                                </>
                                            )
                                        }
                                    </CheckboxButton>

                                    <CheckboxButton value={subscribeCheck} setValue={setSubscribeCheck}>
                                        { t('I agree to subscribe to our newsletter for latest promotions and information')}
                                    </CheckboxButton>
                                    
                                    <CheckboxButton className={yearCheck === false ? 'required-field' : ''} setValue={setYearCheck}>
                                        { t('I am over 18 years old')}. *
                                    </CheckboxButton>

                                </fieldset>


                                <div id="recaptcha-badge">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={sitekey}
                                        theme={'dark'}
                                        hl={lang}
                                        onChange={v2Callback}
                                        tabindex={0}
                                    />
                                </div>

                                <fieldset>
                                    <div className="form-row">
                                        <legend className="hidden">{ t('Take action')}</legend>
                                        <p className="Message Error hidden" id="RegisterError"></p>
                                        <p className="Message Success hidden" id="RegisterSuccess"></p>
                                        { ( !responseState ||  errorResponse ) &&
                                            <Button className="E2E-RegisterSubmitButton Button btn" type="submit">
                                                <span className="ButtonText">{ t('Register')}</span>
                                            </Button>
                                        }
                                    </div>
                                </fieldset>

                                { !responseState && isLoading && <LoadingOutput>{ t('Registering')}...</LoadingOutput> }
                                { errorResponse && <ErrorOutput>{ errorResponse }</ErrorOutput> }
                                { responseState && !errorResponse && <SuccessOutput>{ t('Registered succesfully')}...</SuccessOutput> }
                            </div>
                        </div>  
                    </div>
                </form>
                { accountSmsActivated === false && 
                    <SMSActivationModal 
                    setSmsCode={setSmsCode} 
                    smsCode={smsCode} 
                    mobilePhoneMumber={mobile} 
                    email={email} 
                    password={password} 
                    setPassword={setPassword} 
                    setAccountSmsActivated={setAccountSmsActivated} 
                    accountSmsActivated={accountSmsActivated} />
                }
        </section>
    )
}