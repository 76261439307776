import React, { useRef, useCallback, useEffect, lazy, Suspense } from 'react'
import styled from 'styled-components'

import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

import Links from './Links/Links'
import PaymentLogo from './FooterLogos/PaymentLogo'

import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useUserLogged } from '../../../contexts/UserLoggedProvider'
import { useLocation } from 'react-router-dom'

import './Footer.scss';
import LazyLoad from 'react-lazyload'

/* import TerminateAccount from './../../Authentication/Profile/TerminateAccount/TerminateAccount' */

const Copyright = lazy(() => import('./Copyright'))

const Footer = () => {

	const tawkMessengerRef = useRef()
	const appOptions = useAppOptions()
	const location = useLocation()
    const userLogged = useUserLogged()

	const onLoad = useCallback(() => {
		if(typeof window.Tawk_API.showWidget !== 'function') return
		if(typeof window.Tawk_API.hideWidget !== 'function') return
		if(location.pathname.includes('game/live-poker_mima/play') || location.pathname.includes('game/live-poker_mima/play/free')){
			tawkMessengerRef.current.hideWidget()
		}else{
			tawkMessengerRef.current.showWidget()
		}
	}, [location.pathname])


	useEffect(() => {
		onLoad()
	}, [onLoad, tawkMessengerRef, location])
	

	return (
		<>
			<SiteFooter className='site-footer'>
				{/* <SiteChristmasLights src="/images/christmas/christmas-lights.png" /> */}
                
				<PaymentLogo appOptions={appOptions} />
				<Links appOptions={appOptions} />
                <Suspense fallback={<></>}>
                    <LazyLoad height='200px' once>
                        <Copyright />
                    </LazyLoad>
                </Suspense>
			</SiteFooter>
            { 
            userLogged && userLogged.userLogged &&
            appOptions && 
            appOptions.acf && 
            appOptions.acf.technical_issues && 
            appOptions.acf.technical_issues.activate && <>
                <TechIssues>
                    <Icon src="/images/icons/icon-warning-black.svg" />
                    <span dangerouslySetInnerHTML={{ __html: appOptions.acf.technical_issues.notice }} />
                </TechIssues>
            </> }
			<TawkMessengerReact onLoad={onLoad} ref={tawkMessengerRef} propertyId="62cd521d7b967b11799924f6" widgetId="1g7p0hgth"/>
		</>
	)

}

export default Footer

const SiteFooter = styled.footer`
	position: relative;
	padding-top: 35px;
    background-color: #131313;
    color: #8f949c;
    border-top: 1px solid black;
    a {
        color: rgb(187, 187, 187);
        text-decoration: none;
        &:hover{
            color: white;
        }
    }

    .footer-logo {
        @media (min-width: 0px) and (max-width: 767px){
            text-align: center;
            margin: 25px 0px;
            margin-top: 40px;
        }
        img {
            width: 100%;
            max-width: 150px;
        }
    }

    .footer-links-box {

        h2 {
            line-height: 1.25;
            margin-bottom: 25px;
            margin-top: 0;
            @media(min-width: 1800px) {
                font-size: 30px !important;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            @media (min-width: 0px) and (max-width: 767px){
                text-align: center;   
            }
            li {
                list-style: none;
                margin-bottom: 10px;
            }
        }
    }

    .footer-copyright {
        @media (min-width: 0px) and (max-width: 767px){
            text-align: center;
            .d-flex{
                justify-content: center;
                margin-bottom: 25px;
            }
        }
        @media (min-width: 768px){
            .copyright-right{
                text-align: right;
            }
        }
        .container {
            border-top: 1px solid #ffffff24;
            padding-top: 20px;
            padding-bottom: 20px;
            font-size: 14px;
        }
	}
`

/* const SiteChristmasLights = styled.div`
    position: absolute;
    width: 100%;
    height: 20px;
    opacity: 0.8;
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-image: url(${props => props.src});
    z-index: 90;
    top: 0;
    transform: translateY(-20%);
` */


const TechIssues = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffff50dc;
    z-index: 99999;
    color: black;
    display: flex;
    font-size: 0.825rem;
    line-height: 1.15;
    padding: 1rem 1.5rem;
    align-items: flex-start;
`

const Icon = styled.img`
    width: auto;
    @media (min-width: 0px) and (max-width: 767px){
        margin-bottom: 0.5rem;
        position: absolute;
        top: -1rem;
        left: -1rem;
        opacity: 0.1;
        height: 90px;
    }
    @media (min-width: 768px){
        margin-right: 1rem;
        height: 2rem;
    }
`