import React from 'react'
import styled from 'styled-components';

import Button from '../../../components/Utils/Button/Button'
import { useAppLoaded } from '../../../contexts/AppLoadedProvider';
import { useLoginPopup, useQuickRegisterPopup } from '../../../contexts/LoginPopupProvider';
import { useUserLogged } from '../../../contexts/UserLoggedProvider';
import { t } from 'i18next';

export default function WelcomeSection({ welcomeInfo }) {

    const { appLoaded } = useAppLoaded()
    const { setLoginPopup } = useLoginPopup()
    const { setQuickRegisterPopup } = useQuickRegisterPopup()
    const { userLogged } = useUserLogged()

    if(!welcomeInfo){ return <></> }
    
    return (
        <WelcomeSectionElement>
            <InfoContent>
                <div className="content">
                    <h1>{ welcomeInfo.title && welcomeInfo.title }</h1>
                    <div dangerouslySetInnerHTML={{ __html: welcomeInfo.subtitle && welcomeInfo.subtitle }}></div>
                    <p>{ welcomeInfo.description && welcomeInfo.description }</p>
                    { appLoaded && !userLogged && <div className="buttons">
                        <Button secondary onClickEvent={ () => { setLoginPopup(true) }}>{ t("Login")}</Button>
                        <Button onClickEvent={ () => { setQuickRegisterPopup(true) }}>{ t("Sign Up")}</Button>
                    </div> }
                </div>
            </InfoContent>
        </WelcomeSectionElement>
    );
}

const WelcomeSectionElement = styled.section`
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 18px;
    }
    @media (min-width: 768px){
        margin-top: 75px;
    }
    .content{
        flex-direction: column;
    }
`

const InfoContent = styled.div`
    position: relative;
    box-shadow: 0px 5px 15px black;
    
    @media (min-width: 0px) and (max-width: 767px){
        padding: 35px;
    }
    @media (min-width: 768px){
        padding: 75px 50px;
    }

    &:after{
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: #0000008f;
        z-index: 1;
    }

    .content{
        position: relative;
        z-index: 5;
        margin: 0 auto;
        text-align: center;
        max-width: 800px;
        width: 90%;
        h1{
            margin: 0;
            @media (min-width: 0px) and (max-width: 767px){
                font-size: 24px;
                line-height: 28px;
            }
            @media (min-width: 768px){
                font-size: 32px;
                line-height: 34px;   
            }
        }
        div{
            margin-top: 0px;
            font-weight: 300;
            color: var(--default);
            @media (min-width: 0px) and (max-width: 767px){
                font-size: 18px;   
            }
            @media (min-width: 768px){
                font-size: 22px;
                margin-top: 5px;
            }
        }
        .buttons{
            display: flex;
            justify-content: center;
            margin-top: 35px;
            button{
                margin: 0px 10px;
                min-width: 140px;
            }
        }
    }

`