import React from "react"
import { Link } from 'react-router-dom'
import axios from 'axios'
import useGetWPAxios from '../../../../api/useGetWPAxios'

import { t } from 'i18next'
import { useUserLang } from "../../../../contexts/LanguageSwitcherProvider"

axios.defaults.baseURL = process.env.REACT_APP_API_URL_STAGING

const menuIds = {
	en: 48,
	es: 111,
	ru: 110
}

const HowToPlayMenu = () => {

	const { lang } = useUserLang()
    const { data } = useGetWPAxios('wp/v2/menu?menu_id=' + menuIds[lang])

	if(!data){
		return <></>
	}

	if(data.length===0){
		return <></>
	}
    
    return (
		<div className="footer-links-box terms">
			<h2>{ t("How to Play") }</h2>
			<ul>
				{ data && data.map((item, index) => { 
						return (
							<li key={index}>
								<Link to={(lang !== 'en' ? '/' + lang : '') + '/how-to-play' + item.en} className="link">
									<span dangerouslySetInnerHTML={{ __html: item.name }} />
								</Link>
							</li>	
						)
					})
				}
			</ul>
		</div>
    )
}

export default HowToPlayMenu
