import React from 'react';
import './RadioButton.scss'

export default function RadioButton({label, theme, name, value, selectedValue, required,setValue}) {
    
    return (
        <div className="form-radio-input">
            <label>
                <div className="label">
                    {label}
                </div>
                <input 
                    type="radio"
                    className={theme}
                    name={name}
                    value={value}
                    required={required}
                    onChange={e => {setValue(e.target.value);}}
                    checked={selectedValue === value}
                />
                <span className='checkmark'></span>
            </label>
        </div>
    );
}