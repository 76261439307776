import React, { useRef, useState } from "react"

import { useUserSession } from "../../../../../contexts/UserSessionProvider"

import TextInput from "../../../../Utils/TextInput/TextInput"
import ErrorOutput from "../../../../Output/ErrorOutput/ErrorOutput"
import SuccessOutput from "../../../../Output/SuccessOutput/SuccessOutput"

import Button from "../../../../Utils/Button/Button";
import MyAccountContent from "../Navigation/MyAccountContent";
import LoadingOutput from "../../../../Output/LoadingOutput/LoadingOutput";
import axios from "axios"
import SMSActivationModal from "../../../SMSActivationModal/SMSActivationModal"
import PhoneNumberInput from "../../../../Utils/PhoneNumberInput/PhoneNumberInput"
import { useUserLang } from "../../../../../contexts/LanguageSwitcherProvider"


import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components"
import { t } from 'i18next'

export default function ChangeEmail(){

    const { userSession } = useUserSession()

    const [ mobile_phone_number, setMobilePhoneNumber ] = useState('')
    const [ errorResponse, setErrorResponse ] = useState('')
    const [ response, setResponse ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ requiredCheck, setRequiredCheck ] = useState(false)

    const [ smsCode, setSmsCode ] = useState(null)
    const [ accountSmsActivated, setAccountSmsActivated ] = useState(false)


    const recaptchaRef = useRef(null);

    const { lang } = useUserLang()
    
    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();
    const v2Callback = (token, refreshToken) => {
        setRecaptchaResponse(token)
    }

    const requiredFields = [
        { value: mobile_phone_number , setValue: setMobilePhoneNumber },
    ]

    const handleChangePhoneNumber = async e => {
        e.preventDefault();
        
        setRequiredCheck(false);
        setErrorResponse('')
        setResponse('')
        setLoading(true)

        let requiredFieldsMissing = false;
        requiredFields.map((item) => {
            if(item.value === ''){
                setRequiredCheck(true);
                setErrorResponse( t("Please fill in all the required fields!") );
                requiredFieldsMissing = true
                item.setValue(null);
                setLoading(false)
            }
            return null;
        })

        if(userSession.mobile_phone_number.replace('+', '') === mobile_phone_number.replace('+', '')){
            setResponse(true);
            setErrorResponse(t ("The new Mobile Phone Number can not be the same as your current one.") ); 
            setLoading(false)
            return;
        }

        if(!recaptchaResponse){
            setErrorResponse( t("Please complete the reCaptcha process.") )
        }

        if(!requiredCheck && !requiredFieldsMissing){

            let parameters = {
                "mobile_phone_number": mobile_phone_number,
                recaptcha: recaptchaResponse
            }

            axios.post(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/mobile-phone-number/request-update/',parameters,  {
                headers:{
                    "Authorization": "JWT " + localStorage.getItem('authJwt')
                }
            })
            .then((response) => {
                setLoading(false)
                setResponse(response.data.details)
                setErrorResponse(null)
            }).catch(function (error) {
                recaptchaRef.current?.reset();
                setLoading(false)
                setErrorResponse(error.response.data.details)
            })

        }
    }

    return(
        <>
            <form onSubmit={handleChangePhoneNumber}>
                <div className="profile-settings">
                    
                    <div className="top-bar">
                        <h1>{ t("Change Mobile number") }</h1>
                    </div>

                    <MyAccountContent>
                            
                        { userSession && <TextInput 
                            className="disabled-style"
                            label={ t("Your current Mobile Phone number") }
                            type="text"
                            readonly={true}
                            value={userSession && userSession.mobile_phone_number}
                        /> }

                        <PhoneNumberInput
                            label={ t("New Mobile Phone Number *") }
                            required={true}
                            value={mobile_phone_number && mobile_phone_number}
                            setValue={setMobilePhoneNumber} />

                        <RecaptchaBadgeWrap>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={sitekey}
                                theme={'dark'}
                                hl={lang}
                                onChange={v2Callback}
                                tabindex={0}
                            />
                        </RecaptchaBadgeWrap>

                    </MyAccountContent>

                    { loading && <LoadingOutput>{ t("Changing your mobile phone number...") }</LoadingOutput> }
                    { errorResponse && <ErrorOutput>{ errorResponse }</ErrorOutput> }
                    { response && !errorResponse && <SuccessOutput >{ response }</SuccessOutput> }

                    <div className="buttons-wrap mt-2">
                        { !loading && <Button>{ t("Submit") }</Button> }
                    </div>

                </div>
            </form>
            { !errorResponse && response && (
                <SMSActivationModal 
                    setSmsCode={setSmsCode} 
                    smsCode={smsCode} 
                    mobilePhoneMumber={mobile_phone_number} 
                    updateNumber={true}
                    email={userSession.email} 
                    setAccountSmsActivated={setAccountSmsActivated} 
                    accountSmsActivated={accountSmsActivated} />
            )}
        </>

    )
}

const RecaptchaBadgeWrap = styled.div`
    margin-top: 2rem;
`