import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useUserLogged } from "../../contexts/UserLoggedProvider"
import { useAppOptions } from '../../contexts/AppOptionsProvider'
import { useUserSession } from '../../contexts/UserSessionProvider'

import ErrorOutput from '../../components/Output/ErrorOutput/ErrorOutput'
import SuccessOutput from '../../components/Output/SuccessOutput/SuccessOutput'
import LoadingOutput from '../../components/Output/LoadingOutput/LoadingOutput'
import TextInput from '../../components/Utils/TextInput/TextInput'
import SubmitButton from '../../components/Utils/SubmitButton/SubmitButton'

import { useLoginPopup, useChangePasswordPopup } from '../../contexts/LoginPopupProvider'
import ChangePasswordPopupForm from '../../components/Utils/ChangePasswordPopupForm/ChangePasswordPopupForm'
import { isMobile } from 'react-device-detect'
import { isWebpSupported } from 'react-image-webp/dist/utils';
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";

import { t } from 'i18next'
import styled from 'styled-components'


export default function LoginForm({ returnPath='/' }){

    const location = useLocation()
    const navigate = useNavigate()
    const { setUserSession } = useUserSession()


    const appOptions = useAppOptions()


    const [ randomImage, setRandomImage ] = useState(null)

    const { changePasswordPopup } = useChangePasswordPopup()
    const [password, setPassword] = useState(null)
    const [togglePassword, setTogglePassword] = useState(false)
    
    const [errorResponse, setErrorResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    
    const { userLogged, setUserLogged } = useUserLogged()
    const { loginPopup, setLoginPopup } = useLoginPopup()
    
    const loginPredefined = loginPopup && loginPopup!==true ? loginPopup : false
    const [ usernameOrEmail, setUsernameOrEmail ] = useState(loginPredefined)
    const [ delegateAccount, setDelegateAccount ] = useState('')

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) { setLoginPopup(false) }
    }, [setLoginPopup]);


    useEffect(() => {
        if(!loginPopup) return
        setRandomImage(Math.floor(Math.random() * 3))
    }, [loginPopup])

    const recaptchaRef = useRef(null);
    const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY; // change to your site key
    const [ recaptchaResponse, setRecaptchaResponse ] = useState();
    const v2Callback = (token, refreshToken) => {
        setRecaptchaResponse(token)
    }

    useEffect(() => {
        document.addEventListener("keydown", escFunction);
        return () => { document.removeEventListener("keydown", escFunction); };
    }, [escFunction]);


    useEffect(() => {
        if(userLogged){
            setTimeout(() => {
                if(location.pathname==='/login'){
                    navigate(returnPath)
                }
                setLoginPopup(false)
            }, 1250)
        } 
    }, [location.pathname, navigate, userLogged, returnPath, setLoginPopup])
    

    

    const handleSubmit = async e => {
        e.preventDefault();

        

        let parameters = {
            username: usernameOrEmail,
            password: password,
            recaptcha: recaptchaResponse,
            delegate: delegateAccount,
        }

        if((usernameOrEmail === null || usernameOrEmail === "") && (password === null || password === "")){
            setErrorResponse(t("Username or E-mail and Password cannot be empty"))
            return;
        }

        if(usernameOrEmail === null || usernameOrEmail === ""){
            setErrorResponse(t("Username or E-mail cannot be empty"))
            return;
        }

        if(password === null || password === ""){
            setErrorResponse(t("Password cannot be empty"))
            return;
        }
        
        setIsLoading(true)
        setErrorResponse(null)

        if(!recaptchaResponse){
            setErrorResponse(t("Please complete the reCaptcha process."))
        }

        axios.post(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/auth/v2/delegate-login/', parameters)
        .then((response) => {
            setPassword('')
            setUsernameOrEmail('')
            setUserLogged(true)
            setIsLoading(false)
            localStorage.setItem('authJwt',response.data.token)

            axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/profile-data/', {
                headers:{
                    "Authorization": "JWT " + localStorage.getItem('authJwt')
                }
            })
            .then((response) => {
                setUserSession(response.data)
            })

        }).catch(function (error) {
            
            let error_response = ''
            if(error.response.data.code === "account_not_active"){

                

                
            }else{
                setErrorResponse(error.response.data.details+error_response);
                setIsLoading(false)
            }
            recaptchaRef.current?.reset();
        })
        
    }    

    return (
        <DelegateLoginWrap>
            <div className='login-image' style={{ 
                backgroundImage: 'url('+ (appOptions && randomImage!==null && appOptions.acf.login_images[randomImage].image.url + (isWebpSupported ? '.webp' : '')) +')'
            }}>
            </div>
            <div className="login-wrapper">
                <h3>{ t("Login") }</h3>
                <form onSubmit={handleSubmit}>

                    <TextInput 
                        autoFocus={!isMobile} 
                        required={true} 
                        label={t("Username or E-mail")}
                        type="text" 
                        setValue={setUsernameOrEmail} 
                        inputmode="email" />

                    <TextInput 
                        required={true} 
                        label={t("Delegate account")}
                        type="text" 
                        setValue={setDelegateAccount} 
                        inputmode="text" />

                    <div className="password-wrap">
                        <TextInput required={true} label="Password" type={togglePassword ? "text" : "password"}  setValue={setPassword}  />
                        <img className="btn-pwd-toggle" onClick={() => { setTogglePassword(!togglePassword) }} alt="Toggle Password" src={"/images/icons/icon-eye-"+ (togglePassword ? 'closed' : 'open') +".svg"} />
                    </div>
                

                    <div id="recaptcha-badge">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={sitekey}
                            theme={'dark'}
                            hl='en'
                            onChange={v2Callback}
                            tabindex={0}
                        />
                    </div>
                    
                    <SubmitButton theme="light">{ t("Login") }</SubmitButton>

                    
                    { isLoading && <LoadingOutput>Logging in...</LoadingOutput> }
                    { !userLogged && returnPath && returnPath!=='/' && !errorResponse && !isLoading && <ErrorOutput>{ t("You need to login first") }...</ErrorOutput> }
                    { errorResponse && <ErrorOutput>{ errorResponse }</ErrorOutput> }
                    { userLogged && !isLoading && !errorResponse && <SuccessOutput >{ t("Logged in successfully") }.</SuccessOutput> }
                    
                    
                </form>
            </div>

            { changePasswordPopup && <ChangePasswordPopupForm changePasswordPopup={changePasswordPopup} usernameOrEmail={usernameOrEmail} /> }

        </DelegateLoginWrap>
    )
}

const DelegateLoginWrap = styled('div')`
    max-width:95%;
    width:800px;
    margin: 0 auto;
`