import React, { useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { t } from 'i18next'
import styled from 'styled-components/macro'
import { useUserSession } from '../contexts/UserSessionProvider'
import Button from '../components/Utils/Button/Button'
import LoadingOutput from '../components/Output/LoadingOutput/LoadingOutput'

import { useLoginPopup, useQuickRegisterPopup } from '../contexts/LoginPopupProvider'
import SelectInput from '../components/Utils/SelectInput/SelectInput'
import TextInput from '../components/Utils/TextInput/TextInput'
import ErrorOutput from '../components/Output/ErrorOutput/ErrorOutput'
import SuccessOutput from '../components/Output/SuccessOutput/SuccessOutput'
import { useUserWalletProvider } from '../contexts/UserWalletProvider'
import { useAppOptions } from '../contexts/AppOptionsProvider'

const apiUrl = `${process.env.REACT_APP_WP_API_URL}contact-form-7/v1/contact-forms/2343/feedback/`

const blinds = '16$/32$'
const duration = '2 hours'
const minBuyin = 100
const buyins = minBuyin + '$/100.000$'

const streamingDates = [{ value : "January 10, 2024, 2pm CET" , label: "January 10, 2024, 2pm CET"}]

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	if (completed) {
		return (
			<JoinGameWrap>
				<SpicyButton />
			</JoinGameWrap>	
		)
	} else {
		return (
			<CountdownNote>
				Time until the stream: <span>{days} days | {hours}:{minutes}:{seconds}</span>
			</CountdownNote>
		)
	}
}

const SpicyButton = () => {
	const appOptions = useAppOptions()
	const { userSession } = useUserSession()
	if(appOptions && userSession && appOptions.acf.spicy_table_id){
		return <Button 
			colorTheme="orange" 
			to={"/game/live-poker_mima/play/spicy/" + appOptions.acf.spicy_table_id}>
				Join the Spicy Table 🔥
		</Button>
	}else{
		return <LoadingOutput>Stay tuned, we will have new spicy sessions very soon! 🔥</LoadingOutput>
	}
	
}

function LandingSpicyPage() {

    const formatter = new Intl.NumberFormat('en-US')
	const formRef = useRef()
	const { userSession } = useUserSession()
	const { userWallet } = useUserWalletProvider()

    const money =  formatter.format((userWallet.amount * 100/ 100).toFixed(2))

	const { setLoginPopup } = useLoginPopup()
	const { setQuickRegisterPopup } = useQuickRegisterPopup()

	const [isValid, setIsValid] = useState(true)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [errorMessages, setErrorMessages] = useState([])

	const type = 'player'
	//const [type, setType] = useState('player')
	const [eventDate, setEventDate] = useState(null)
	const [agreed, setAgreed] = useState(false)

	const jsonToFormData = (json) => {
		try {
			const data = new FormData()
			for (let k in json) {
				data.append(k, json[k])
			}
			return data
		} catch (error) {
			console.error(error)
			return null
		}
	}

	/* const toggleType = (type) => {
		setType(type)
		setTimeout(() => {
			window.scrollTo({ top: formRef.current.offsetTop-200, left: 0, behavior: 'smooth' })
		}, 100)
	} */

	const sendRequest = () => {

		let valid = true
		if(!userSession) return

		let errorMessagesArray = []
		if(!type) { 
			valid = false
			errorMessagesArray.push('Type of request is required field.')
		}
		if(!eventDate) { 
			valid = false
			errorMessagesArray.push('Date of the event is required field.')
		}
		if(!userSession.email) { 
			valid = false
			errorMessagesArray.push('Email Address is required field.')
		}
		if(!userSession.username) { 
			valid = false
			errorMessagesArray.push('Username is required field.')
		}
		if(!agreed) { 
			valid = false
			errorMessagesArray.push('You have to agree to our terms and conditions before requesting the form.')
		}

		if(!valid) {
			setIsValid(false)
			setErrorMessages(errorMessagesArray)
			return
		}

		setLoading(true)
		setErrorMessages(null)

		let formInputsData = {}
		formInputsData['user_email'] = userSession.email
		formInputsData['user_name'] = userSession.username
		formInputsData['user_current_balance'] = money + ' ' + userWallet.currency
		formInputsData['selected_date'] = eventDate

		fetch(apiUrl, {
            method: "POST",
            body: jsonToFormData(formInputsData),
        })
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp.status !== "mail_sent") throw resp.message
				setSuccess(true)
        })
        .catch((error) => { setErrorMessages([error]) })
        .finally(() => { setLoading(false) })

		/* console.log('Sending:')
		console.log(type)
		console.log(eventDate)
		console.log(userSession.email)
		console.log(userSession.username)
		console.log(agreed) */
	}

	return (
		<>
			<SpicySection>
				<div className="container">
					<div className="row justify-content-between align-items-center">
						<div className="col-xl-6">
							<SpicyIntroContent>
								<h1>Spicy Poker Dealer Seduction! 💋</h1>
								<h2>🔥 "Let's make this poker night unforgettable, as we set the table ablaze with our electrifying chemistry. Can you handle the intensity of our desire?" 🃏🌶️</h2>

								<Countdown zeroPadTime={2} renderer={renderer} date={'January, 10, 2024 13:00:00 GMT+0200'} />
								
								{ userSession && (
									<SpicyTypeSelection>
										Follow for more exclusive spicy content on Telegram:
										{/* <TypeSelectionWrap>
											<TypeLabel hasSelection={type ? '1' : '0'} active={type==='spectator' ? '1' : '0'} htmlFor="spectator">
												<img alt="Spectator" src="/spicy/images/icon-spectator.svg" />
												<input onClick={() => { toggleType('spectator') }} name="join-type" id="spectator" type="radio" value="spectator" /> Spectator
											</TypeLabel>
											<TypeLabel hasSelection={type ? '1' : '0'} active={type==='player' ? '1' : '0'} htmlFor="player">
												<img alt="Player" src="/spicy/images/icon-player.svg" />
												<input onClick={() => { toggleType('player') }} name="join-type" id="player" type="radio" value="player" /> Player
											</TypeLabel>
										</TypeSelectionWrap> */}
									</SpicyTypeSelection>
								) }
								{ !userSession && (
									<NotLoggedWrap>
										<LoadingOutput>You are not logged in. Please login to proceed with the request.</LoadingOutput>
										<LoginButtons>
											<Button secondary colorTheme={'orange'} onClickEvent={ () => { 
												setLoginPopup(true); }}>{ t("Login") }</Button>
											<Button colorTheme={'orange'} onClickEvent={ () => { setQuickRegisterPopup(true); }}>{ t("Sign Up") }</Button>
										</LoginButtons>
									</NotLoggedWrap>
								) }

								<JoinTelegramWrap>
									<JoinTelegram href="tg://resolve?domain=mimapokerlive">
										<img alt="Join Telegram Channel" src="/images/icons/icon-telegram.svg" />
										Join Telegram Channel
									</JoinTelegram>
								</JoinTelegramWrap>

							</SpicyIntroContent>
						</div>
						<div className="col-xl-6">
							<SpicyVideoBox>
								<SpicyVideo muted autoPlay controls src="/spicy/videos/spicy-dealer-promo-video.mp4" />
							</SpicyVideoBox>
						</div>
					</div>
				</div>

				{/* <ArrowDown src="/images/icons/icon-arrow-down.svg" /> */}

			</SpicySection>
			<SpicyFormSection active={(false && type!==null && userSession) ? '1' : '0'} ref={formRef}>
				
				{ false && type!==null && userSession && (
					<FormSection>
						<FormIntro>
							
							{ type==='player' && <div>
								<FormTitle>Player Request</FormTitle>
								<p>As a player you will be able to play poker against other players and interact/chat with the Spicy Dealer.</p>
								{/* <EntranceFeeWrap>
									<p>Entrance fee:<br /></p>
									<EntranceFee>{ entranceFee }$</EntranceFee>
								</EntranceFeeWrap> */}
								<EntranceFeeWrap>
									<p>Blinds:<br /></p>
									<EntranceFee>{ blinds }</EntranceFee>
								</EntranceFeeWrap>
								<EntranceFeeWrap>
									<p>Min/Max Buyin:<br /></p>
									<EntranceFee>{ buyins }</EntranceFee>
								</EntranceFeeWrap>
								<EntranceFeeWrap>
									<p>Duration:<br /></p>
									<EntranceFee>{ duration }</EntranceFee>
								</EntranceFeeWrap>
							</div> }
							{ type==='spectator' && <div>
								<h3>Spectator Request</h3>
								<p>As a spectator you will be able to watch the stream and the game, but will not be able to interact with the Spicy Dealer.</p>
								{/* <EntranceFeeWrap>
									<p>Entrance fee:<br /></p>
									<EntranceFee>{ entranceFee }$</EntranceFee>
								</EntranceFeeWrap> */}
								<EntranceFeeWrap>
									<p>Duration:<br /></p>
									<EntranceFee>{ duration }</EntranceFee>
								</EntranceFeeWrap>
							</div> }

							<EntranceForm>
								<h3>Please fill in the form to request to join</h3>
								
								<FormRow>
									<TextInput 
										className="disabled-style" 
										type="text" 
										readonly={true} 
										name="user_email" 
										value={userSession.email} />
								</FormRow>
								<FormRow>
									<TextInput 
										className="disabled-style" 
										type="text" 
										readonly={true} 
										name="selected_date" 
										value={userSession.username} />
								</FormRow>

								<FormRow>
									<p>Select which date you want to join:</p>
									<SelectInput 
										options={streamingDates} 
										value={ eventDate } 
										setValue={ setEventDate } 
										name="user_name" 
										required={true} />
								</FormRow>

								<TermsWrap>
									<input name="spicyTerms" type="checkbox" onClick={() => { setAgreed(!agreed) }} />
									I Agree with the <a target="_blank" href="/security-privacy/terms-conditions/">Terms & Conditions</a> of this event.</TermsWrap>

								{ money < minBuyin && <LoadingOutput>You currently have lower balance ({ money }$) then the minimum buy in for this table.<br /> Please <a target="_blank" href="/my-account/deposit">deposit</a> minimum { parseFloat(minBuyin-money).toFixed(2) }$ before requesting to join.</LoadingOutput> }
								{ money >= minBuyin && <div className="d-flex justify-content-end">
									{ !success && <Button onClickEvent={sendRequest}>Send Request</Button> }
								</div> }

								{ !isValid && errorMessages && errorMessages.length>0 && <ErrorOutput>
									<ErrorMessagesList>
										{ errorMessages.map((message, index) => {
											return <li key={index}>{ message }</li>
										}) }
									</ErrorMessagesList>
								</ErrorOutput> }

								{ loading && <LoadingOutput>Sending request...</LoadingOutput> }
								{ success && <SuccessOutput>Request sent. We will send you the link to the table by email, once we approve your request.</SuccessOutput> }
								
							</EntranceForm>
						</FormIntro>
					</FormSection>
				)}

			</SpicyFormSection>
		</>
	)
}

export default LandingSpicyPage

const NotLoggedWrap = styled.div`
	background: #00000038;
	border: 1px solid #333;
	border-radius: 1rem;
	padding: 1rem 1.5rem;
	padding-bottom: 1.5rem;
	margin-top: 1rem;
`

const LoginButtons = styled.div`
	display: flex;
	justify-content: center;
	@media (min-width: 0px) and (max-width: 767px){
		flex-direction: column;
		align-items: center;
		button{
			min-width: 100%;
			width: 100%;
			margin: 0.5rem 0;
		}
	}
	button{
		@media (min-width: 768px){
			margin-right: 1rem;
			min-width: 200px;
		}
	}
`

const JoinTelegramWrap = styled.div`
	@media (min-width: 0px) and (max-width: 767px){
		text-align: center;
	}
	@media (min-width: 0px) and (max-width: 1199px){
		margin-bottom: 4rem;
	}
`

const JoinTelegram = styled.a`
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	color: white;
	background: var(--default);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    width: 100%;
	max-width: 350px;
    justify-content: center;
	@media (min-width: 0px) and (max-width: 767px){
		margin: 0 auto;
		margin-bottom: 2rem;
		margin-top: 1.5rem;
	}
	img{
		width: 1rem;
		height: auto;
		margin-right: 0.5rem;
	}
`

const JoinGameWrap = styled.div`
	button{
		min-width: 100%;
		width: 100%;
		padding: 1.25rem 2rem;
	}
`

const SpicySection = styled.section`
	position: relative;
	background-image: url('/spicy/images/20231106-DSC04845-Edit.jpg');
	background-size: cover;
	background-position: 50%;
	border-bottom: 5px solid #ff6000;
	@media (min-width: 0px) and (max-width: 767px){
		padding: 3rem 0;
		padding-bottom: 5rem;
	}
	@media (min-width: 768px){
		padding: 8rem 0;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: #000000c9;
	}
	.container{
		position: relative;
		z-index: 5;
	}
`

const SpicyVideoBox = styled.div`
	position: relative;
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: -7.5px;
		left: -7.5px;
		z-index: -1;
		width: calc(100% + 15px);
		height: calc(100% + 10px);
		background: linear-gradient(
			45deg,
			#ffff00,
			#ff9100,
			#ff4400,
			#ff0000,
			#ff6f00,
			#ffb300,
			#ffff00
		);
		background-size: 150%;
		-webkit-animation: border 12s linear infinite;
		animation: border 12s linear infinite;
		border-radius: 1rem;
	}
	&::after {
		filter: blur(50px);
	}
	@keyframes border {
		0%, 100% {
			background-position: 0 0;
		}
		50% {
			background-position: 150%;
		}
	}
`

/* const ArrowDown = styled.img`
	position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 90;
    width: 2rem;
` */

const SpicyVideo = styled.video`
	width: 100%;
	height: auto;
	border-radius: 0.75rem;
`

const SpicyIntroContent = styled.div`
	
	@media (min-width: 1200px){
		padding-right: 3rem;
	}
	h1{
		@media (min-width: 0px) and (max-width: 767px){
			font-size: 2rem;
		}
		@media (min-width: 768px){
			font-size: 2.5rem;
		}
		line-height: 1.5;
		margin: 0;
	}
	h2{
		font-size: 1.125rem;
		line-height: 1.5;
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 1.5rem;
	}
`

const SpicyTypeSelection = styled.div`
	margin-top: 1.5rem;
`


/* const TypeSelectionWrap = styled.div`
	display: flex;
`

const TypeLabel = styled.label`
	padding: 0.5rem 2rem;
    border-radius: 1rem;
    border: 2px solid white;
    display: flex;
    align-items: center;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1.25rem;
	letter-spacing: 3px;
	transition: all .2s ease-out;
	margin-right: 1rem;

	${props => props.hasSelection==='1' ? css`
		img{
			width: 1.5rem;
			height: 1.5rem;
		}
	` : css`
	`}

	${props => props.active==='1' ? css`
		background: linear-gradient(45deg,#ff0000,#ff6f00);
	` : css`
		background: none;
	`}
	&:hover{
		background: #ff3c0054;
	}
	img{
		width: 2.5rem;
		height: 2.5rem;
		height: auto;
		margin-right: 1rem;
		object-fit: contain;
		transition: all .2s ease-out;
	}
	input{
		display: none;
	}
` */


const SpicyFormSection = styled.section`
	transition: all .4s ease-out;
	text-align: center;
	@media (min-width: 0px) and (max-width: 767px){
		padding: ${props => props.active==='1' ? `1.5rem` : `0`};
	}
	@media (min-width: 768px){
		padding: ${props => props.active==='1' ? `3rem` : `0`};
	}
`

const FormTitle = styled.h3`
	font-size: 2rem;
	background: -webkit-linear-gradient(272deg, #ff0000,#ffa056);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
`

const FormSection = styled.div`
	padding: 2rem 0;
`

const FormIntro = styled.div`
	margin: 0 auto;
	h3{
		margin: 0rem;
	}
`

const EntranceFeeWrap = styled.div`
    display: inline-flex;
    align-items: center;
    border: 1px solid #343434;
	border-radius: 5rem;
    padding: 1rem 1.5rem;
	margin: 0.5rem;
	background: linear-gradient(45deg, #232323, transparent);
	@media (min-width: 0px) and (max-width: 767px){
		width: 100%;
		padding: 0.25rem 1.5rem;
	}
	
	@media (min-width: 768px){
		padding: 1rem 1.5rem;
	}
`

const EntranceFee = styled.div`
    font-weight: bold;
    background: -webkit-linear-gradient(272deg, #ff0000,#ffa056);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	margin-left: 0.5rem;
	@media (min-width: 0px) and (max-width: 767px){
		font-size: 1.5rem;
	}
	@media (min-width: 768px){
		font-size: 2rem;
	}
`

const EntranceForm = styled.div`
	border-radius: 1rem;
	border: 1px solid #333;
	text-align: left;
	margin: 0 auto;
	margin-top: 2rem;
	max-width: 750px;
	width: 100%;
	@media (min-width: 0px) and (max-width: 767px){
		padding: 2.5rem 2rem;
	}
	@media (min-width: 768px){
		padding: 2.5rem 3rem;
	}
	h3{
		margin: 0;
		margin-bottom: 1rem;
	}
	p{
		margin: 0.5rem 0;
	}
	.loading-output{
		padding: 1.5rem 0;
		line-height: 1.25;
		a{
			color: black;
			font-weight: bold;
		}
	}
`

const TermsWrap = styled.label`
	position: relative;
	cursor: pointer;
	display: block;
	margin: 1rem 0;
	margin-top: 1.25rem;
	margin-bottom: 3rem;
	padding-left: 2rem;
	line-height: 1.5;
	input{
		position: absolute;
		left: 0;
		width: 1.25rem;
		height: 1.25rem;
	}
`

const FormRow = styled.div`
	margin: 1rem 0;
`

const ErrorMessagesList = styled.ul`
	text-align: left;
	li{
		font-size: 0.875rem;
		line-height: 1.25;
		margin: 0;
	}
`

const CountdownNote = styled.div`
	display: inline-flex;
	align-items: center;
	border: 1px solid #343434;
	border-radius: 1rem;
	padding: 1rem 1.5rem;
	margin: 0;
	background: linear-gradient(45deg, #232323, #000000d8);
	@media (min-width: 0px) and (max-width: 767px){
		width: 100%;
		flex-direction: column;
	}
	span{
		margin-left: 1rem;
		font-size: 2rem;
		font-weight: bolder;
		background: -webkit-linear-gradient(272deg, #ff0000,#ffa056);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
`