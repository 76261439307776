import React, { useEffect } from "react"
import { useLocation } from 'react-router-dom'

import { useLoginPopup } from "../../contexts/LoginPopupProvider"

import LoginForm from "../../components/Authentication/LoginForm/LoginForm"
import Seo from "../../components/General/Seo/Seo"

export default function Login(){

    const location = useLocation()
    const { loginPopup, setLoginPopup } = useLoginPopup()

    useEffect(() => {
        setLoginPopup(true)
    }, [loginPopup, setLoginPopup])

    return (
        <Seo 
        url={process.env.REACT_APP_URL + 'login'}
        title={process.env.REACT_APP_MAIN_TITLE + ' - Login'}>
            <LoginForm returnPath={location?.state?.returnPath} />
        </Seo>
    )
}
