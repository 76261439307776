import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { t } from 'i18next';
import PublicTables from './PublicTables';
import LobbyAside from './LobbyAside';
import Invitations from './Invitations';
import { useAppOptions } from '../../../contexts/AppOptionsProvider';

export default function MainPokerLobby({ currentTable = null }){

    const [currentDomain, setCurrentDomain] = useState('');
    const appOptions = useAppOptions()

    useEffect(() => {
        setCurrentDomain(window.location.hostname);
    }, []);

    return (
        <MainPokerLobbyWrap id="main-poker-lobby">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-11">
                        <MainPokerLobyInner>

                            { currentDomain.includes('localhost') && (
                                <NavBar>
                                    <TabsWrap>
                                        <BackButton>back</BackButton>
                                        <SingleTab className='selected-tab'>Public Tables</SingleTab>
                                        <SingleTab>MiMa CLubs</SingleTab>
                                        <SingleTab>My Clubs</SingleTab>
                                    </TabsWrap>
                                    <SearchWrap>
                                        <SearchInput type="email" placeholder={t("Search clubs")} />
                                        <SubmitSearch type="submit" value={ t("Search")} />
                                    </SearchWrap>
                                </NavBar>
                            )}
                            { !currentDomain.includes('localhost') && (
                                <Heading>
                                    <Title>
                                        {   appOptions && 
                                            appOptions.acf.poker_tables_lobby && 
                                            appOptions.acf.poker_tables_lobby.live_tables && 
                                            <h3 dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.title }} /> }
                                    </Title>
                                    <div className='iconDesc'> 
                                    {   appOptions && 
                                            appOptions.acf.poker_tables_lobby && 
                                            appOptions.acf.poker_tables_lobby.live_tables && 
                                            <p dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.description }} /> }
                                    </div>
                                </Heading>
                            )}

                            <MainContentWrap>
                                <MainContentInner>
                                    { currentDomain.includes('localhost') && (
                                        <Invitations />
                                    )}
                                    <PublicTables currentTable={currentTable} />
                                </MainContentInner>
                                <AsideInfo>
                                    <LobbyAside />
                                </AsideInfo>
                            </MainContentWrap>

                        </MainPokerLobyInner>
                    </div>
                </div>
            </div>
        </MainPokerLobbyWrap>
    )
}


const Heading = styled.div`
    margin-top: 0rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1.5rem;
    max-width: 715px;
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 2rem;
        flex-direction: row;
        align-items: flex-start;
    }
    @media (min-width: 768px){
        display: flex;
    }
    h3{
        font-size: 2rem;
    }
    p{
        margin: 0;
    }
`

const Title = styled.div`
    color: var(--orange);
`

const MainPokerLobbyWrap = styled.section `
    margin: 2.5rem 0;
    @media (min-width: 1100px){
        margin: 5rem 0;
    }
    a {
        font-weight: bold;
        letter-spacing: 2px;
        background-color: #353535;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        text-decoration: none;

        font-size: 11px;
        padding: 0.5rem 0.75rem;
        @media (min-width: 1100px){
            font-size: 14px;
            padding: 10px 27px;
        }

        &:hover {
            background-color: #f49436;
        }
    }
`

const MainPokerLobyInner = styled.section `
    background: #121212;
    border: 1px solid var(--orange);
    border-radius: 1rem;
    h3{
        margin-top: 0.5rem;
        margin-bottom: 0.75rem;
    }
    @media (min-width: 1025px){
        border: 2px solid var(--orange);
        padding: 18px;
    }
`

const NavBar = styled.div `
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 25px;
    
    @media (min-width: 1025px){
        font-size: 20px;
        flex-direction: row;
    }
    
    input {
        font-size: 14px;
        border: 1px solid #F49436;
        padding: 5px 15px;
        background-color: transparent;
        color: white;
        outline: none;
        min-height: 71px;
        
        @media (min-width: 1025px){
            padding: 25px 50px;
            font-size: 20px;
        }
        
        &::placeholder {
            color: white !important;
        }
    }
`

const TabsWrap = styled.div `
    display: flex;
    border-right: none;
    overflow: hidden;
    margin-bottom: 18px;
    
    @media (min-width: 1025px){
        margin-right: 18px;
        margin-bottom: 0;
    }
`

const BackButton = styled.div `
    background-color: #f49436;
    min-height: 71px;
    width: 70px;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px 0 0 0;
    cursor: pointer;
    padding: 5px 15px;
    
    @media (min-width: 1025px){
        padding: 15px 25px;
    }
    
    @media (min-width: 1600px){
        padding: 25px 50px;
    }
`

const SingleTab = styled.div `
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: 1px solid #F49436;
    cursor: pointer;
    padding: 5px 15px;
    
    @media (min-width: 1025px){
        padding: 15px 25px;
    }
    
    @media (min-width: 1600px){
        padding: 25px 50px;
    }

    &:first-child {
        border-radius: 9px 0 0 0;
    }

    &:last-child {
        border-radius: 0 9px 0 0;
        
        @media (min-width: 1025px){
            border-radius: 0;
        }
    }

    &.selected-tab {
        background-color: #f49436;
    }
`

const SearchWrap = styled.div `
    display: flex;
    flex: auto;
`

const SearchInput = styled.input `
    width: 100%;
    border-radius: 0 0 0 9px;
    
    @media (min-width: 1025px){
        border-radius: 0;
    }
`

const SubmitSearch = styled.input `
    background-color: #f49436 !important;
    text-transform: uppercase;
    margin-left: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0 0 9px 0;
    
    @media (min-width: 1025px){
        border-radius: 0 9px 0 0;
    }
`

const MainContentWrap = styled.div `
    display: flex;
    flex-direction: column;
    
    @media (min-width: 1025px){
        flex-direction: row;
    }
`

const MainContentInner = styled.div `

    >div {
        width: 100%;
    }
    
    @media (min-width: 1025px){
        width: 80%;
    }
`
const AsideInfo = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: #353535;
    border-radius: 0 0 1rem 1rem;
        
    @media (min-width: 1025px){
        width: 20%;
    }
`