import { t } from 'i18next';
import React from 'react'
import styled, { css } from 'styled-components';

export default function SubscribeSection({ title = "Subscribe to our newsletter", content = "Be the first to discover our exclusive promotions<br /> and receive amazing bonuses. Subscribe now!" }) {
    
    return (
        <SubscribeSectionElement>
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-md-9 col-xl-6">
                        
                        <SectionTitle>{ t(title) }</SectionTitle>
                        <SectionText dangerouslySetInnerHTML={{ __html: t(content) }} />
                        
                        <div id="mc_embed_signup">
                            <form action="https://casino.us14.list-manage.com/subscribe/post?u=37281439e1346337e5b3b64d5&amp;id=bfb67c3827&amp;f_id=003f89e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self">
                                <div id="mc_embed_signup_scroll">
                                    <MCFieldGroup className="mc-field-group">
                                        <EmailInput type="email" placeholder={t("E-mail Address") + " *"} defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" required />
                                        <SubmitButton type="submit" value={ t("Subscribe")} name="subscribe" id="mc-embedded-subscribe" className="button" />
                                        <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                                    </MCFieldGroup>
                                    <MCResponses>
                                        <div className="response" id="mce-error-response" style={{ "display" : "none"}}></div>
                                        <div className="response" id="mce-success-response" style={{ "display" : "none"}}></div>
                                    </MCResponses>
                                    <div style={{ "position": "absolute", "left": "-5000px" }} aria-hidden="true">
                                        <input type="text" name="b_37281439e1346337e5b3b64d5_bfb67c3827" tabIndex="-1" defaultValue="" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </SubscribeSectionElement>
    );
}

const SectionTitle = styled.p`
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: bold;
    @media (min-width: 768px){
        font-size: 2rem;
    }
`

const SectionText = styled.p`
    margin-top: 0;
`

const SubscribeSectionElement = styled.section`
    position: relative;
    padding: 10rem 0;
    background-image: url('/images/subscribe-bg-4.jpg');
    background-size: cover;
    background-position: 0% 0%;
    border-top: 5px solid var(--orange);
    border-bottom: 5px solid var(--orange);
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @media (min-width: 0px) and (max-width: 767px){
            background: #00000063;
        }
        @media (min-width: 768px){
            display: none;
        }
    }
    .container{
        position: relative;
        z-index: 10;
    }
    @media (min-width: 0px) and (max-width: 767px){
        padding-top: 5rem ;
        padding-bottom: 5rem;
    }
`

const inputStyle = css`
    border: 2px solid white;
    background: none;
    padding: 1rem 1.5rem;
    color: white;
    border-radius: 6px;
    font-size: 1.125rem;
    @media (min-width: 0px) and (max-width: 767px){
        width: 100%;
        margin-bottom: 1rem;
    }
    &:focus{
        outline: none;
    }

`

const EmailInput = styled.input`
    ${inputStyle}
    background: #00000041;

    @media (min-width:768px){
        min-width: 350px;
    }
`

const SubmitButton = styled.input`
    ${inputStyle}
    cursor: pointer;
    max-width: 250px;
    background: linear-gradient(to bottom, var(--orange), var(--orange-hover));
    font-weight: bold;
    border: 2px solid var(--orange);
    transition: all .2s ease-out;
    @media (min-width: 768px){
        margin-left: 1rem;
    }
    @media (min-width: 1025px){
        &:hover {
            border-color: white;
        }
    }
`

const MCFieldGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const MCResponses = styled.div``