import React from 'react'

import styled from 'styled-components'

import { buttonElementCss } from '../Button/ButtonElementCss'

const ButtonLink = ({onClickEvent=null, className, colorTheme="default", children, theme='', href='#', target="_self", secondary=false}) => {
	if(!onClickEvent){
		return (
			<LinkElement 
			rel="noreferrer" 
			href={href} 
			target={target} 
			colorTheme={colorTheme} 
			secondary={secondary.toString()}
			className={'btn' + (theme ? ' ' + theme : '')  + (className ? ' ' + className : '') + (secondary ? ' btn-secondary' : '')}>{children}</LinkElement>
		)
	}else{
		return (
			<LinkElement onClick={(e) => { 
				e.preventDefault();
				onClickEvent();
			}} 
			rel="noreferrer" 
			href={href} 
			target={target} 
			colorTheme={colorTheme} 
			secondary={secondary.toString()}
			className={'btn' + (theme ? ' ' + theme : '')  + (className ? ' ' + className : '') + (secondary ? ' btn-secondary' : '')}>{children}</LinkElement>
		)
	}
}

export default ButtonLink

const LinkElement = styled.a`
	${buttonElementCss}
`