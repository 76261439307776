import React from "react"
import './SocialMenu.scss'
import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"
import { t } from 'i18next'

const SocialMenu = ({ hideTitle = false, social_media }) => {
    return (
        <div className="footer-links-box social">
            { !hideTitle && <h2>{ t("Social media")}</h2> }
            <SocialMenuUL>
                { social_media && Object.keys(social_media).map(function(key) {
                    return (
                        social_media[key] && (
                            <li key={key}>
                                <a target="_blank" rel="noreferrer" alt={key} title={key} href={social_media[key]}>
                                    <LazyLoadImage width="30" height="30" alt={key} src={`/images/icons/social/${key}.svg`} />
                                </a>
                            </li>
                        )
                    )
                })}
            </SocialMenuUL>
        </div>
    )
}

export default SocialMenu

const SocialMenuUL = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 15px;
    li{
        margin-right: 0.5rem;
        img, svg{
            width: 34px;
            height: auto;
        }
        
        svg path{
            opacity: 0.8;
            transition: all .3s ease-out;
        }

        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                transition: all .5s #{$i * .2}s ease-out;
            }
        }

        svg:hover{
            path{
                opacity: 1;
                fill: var(--orange);
            }
        }

    }
`
