import React from 'react'
import './OurTeam.scss'

const OurTeamBox = ({item}) => {
    return (
        <div className="col-lg-3 col-md-4 col-6">
            <div className="our-team-box">
                <div className='our-team-image'
                    style={{ 
                        backgroundImage: 'url("'+item.image+'")' 
                    }}
                >   
                <div className="background-image-gif" 
                style={{ 
                    backgroundImage: 'url("'+item.gif['url']+'")' 
                }}
                ></div>
                </div>
                <div className="our-team-box-text">
                    <h3 className='member-name'>{item.name}</h3>
                    <p className='member-position'>{item.position}</p>
                </div>
            </div>
        </div>
    )
}

export default OurTeamBox