import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useGetWPAxios from '../../../api/useGetWPAxios'

import { t } from 'i18next'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'

import './TermsSidebar.scss'

const menuIds = {
	en: 8,
	es: 86,
	ru: 100
}

const TermsSidebar = () => {

    const { lang } = useUserLang()
    const { data, error } = useGetWPAxios('wp/v2/menu?menu_id=' + menuIds[lang])

    const location = useLocation()
    const [activeCat, setActiveCat] = useState('')

    useEffect(() => {
        let pathWithoutDomain = '/' + location.pathname.split('/').slice(1).join('/')
        pathWithoutDomain = pathWithoutDomain.replaceAll('/' + lang, '')
        setActiveCat(pathWithoutDomain)
    }, [lang, location])

    return (
        <div className="terms-sidebar">

            { error && <p>{ t("Error loading items...")}</p> }

            <ul>
                { data && data.map((item, index) => {
                    return(
                        <li key={index} className={item.en === activeCat ? 'active' : ''}>
                            <Link to={(lang !== 'en' ? '/' + lang : '') + item.en} className="link"><span dangerouslySetInnerHTML={{ __html: item.name }} /></Link>
                        </li>
                    )
                }) }
            </ul>


        </div>
    )
}

export default TermsSidebar