import React from 'react'
import styled from 'styled-components';

export default function FaqSection({ faqSection, colorTheme = 'default' }) {

    if(!faqSection){ return <></> }
    
    return (
        <FaqSectionElement className="container">
            <div className="row justify-content-center">
                <div className="col-md-7 col-xl-6">
                    <InfoContent>
                        { faqSection.title && <FaqTitle>{ faqSection.title }</FaqTitle> }
                        { faqSection.text && <div dangerouslySetInnerHTML={{ __html: faqSection.text }} /> }
                    </InfoContent>
                    { faqSection.faq && 
                        <FaqContent>
                            { faqSection.faq.map((item, index) => {
                                return (
                                    <FaqItem colorTheme={colorTheme} key={index}>
                                        <input type="checkbox" id={"rd" + index} name="rd" />
                                        <label className="tab-label" htmlFor={"rd" + index}>
                                            <FaqNumber>{ (index+1) + '. ' }</FaqNumber>
                                            { item.question }
                                        </label>
                                        <div className="tab-content" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                                    </FaqItem>
                                )
                            })}
                        </FaqContent>
                    }
                </div>
            </div>
        </FaqSectionElement>
    );
}

const FaqSectionElement = styled.section`
    padding-bottom: 2rem;
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 35px;
    }
    @media (min-width: 768px){
        margin-top: 75px;
    }
`

const FaqTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    &:after{
        content: '';
        display: block;
        margin: 1rem auto;
        margin-bottom: 2rem;
        width: 20%;
        height: 5px;
        border-radius: 35px;
        background: var(--orange-hover);
    }
`

const InfoContent = styled.div`
    margin-bottom: 25px;
    text-align: center;
`

const FaqContent = styled.div``

const FaqNumber = styled.span`
    position: absolute;
    font-size: 4rem;
    font-weight: bold;
    left: 0;
    top: -20px;
    bottom: -5px;
    margin: auto;
    opacity: 0.085;
    color: ${props => 'var(--'+ props.colorTheme +'-hover)' };
`

const FaqItem = styled.div.attrs({ className: 'tab' })`

    input{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
    }


    .tab-label{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 1.5rem 2rem 1.5rem 1.5rem;
        border-bottom: 1px solid gray;
        transition: all .2s ease-out;
        border-radius: 15px;
        margin-bottom: 0.5rem;
        &:hover {
            background: ${props => 'var(--'+ props.colorTheme +'-hover)' };
        }
        &:after{
            content: '+';
            position: absolute;
            top: 0;
            right: 1rem;
            bottom: 0;
            margin: auto;
            font-size: 1rem;
            font-weight: 100;
            display: flex;
            align-items: center;
        }
    }

    .tab-content{
        max-height: 0;
        opacity: 0;
        padding: 0 1em;
        overflow: hidden;
        transition: all .35s;
        background: #00000082;
        line-height: 1.5rem;
        font-weight: 100;
        border: 1px solid ${props => 'var(--'+ props.colorTheme +'-hover)' };
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        margin-right: 0.6rem;
        margin-left: 0.6rem;
        position: relative;
        top: -9px;

        table{
            width: 100%;
            table-layout: fixed;
            border: 1px solid white;
            border-spacing: 0;
            font-size: 0.825rem;
            line-height: 1.25rem;
            @media (min-width: 1200px){
                font-size: 1rem;
                line-height: 1.35rem;
            }
            td{
                padding: 0.25rem 0.5rem;
                border: 1px solid white;
                word-break: break-word;
            }
            thead{
                tr{
                    background: var(--orange-hover);
                }
            }
            tbody{
                tr:nth-child(odd){
                    background: rgb(33, 33, 33);
                }
            }
        }

    }

    input:checked {
        & + .tab-label {
            background: ${props => 'var(--'+ props.colorTheme +'-hover)' };
            &::after {
                transform: rotate(90deg);
            }
            ${FaqNumber}{
                opacity: 0.2;
            }
        }
        & ~ .tab-content {
            max-height: 100vh;
            padding: 1rem 1rem;
            flex-direction: row;
            padding-bottom: 1.5rem;
            opacity: 1;
            margin-bottom: 0.5rem;

            @media (min-width: 768px){
                padding: 1rem 2rem;
            }
        }
    }

`