import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import RegisterForm from "../../components/Authentication/RegisterForm/RegisterForm"
import Seo from "../../components/General/Seo/Seo";
import { useUserLogged } from "../../contexts/UserLoggedProvider";

export default function Register(){

    const navigate = useNavigate()
    const { userLogged } = useUserLogged()
    useEffect(() => {
        if(userLogged){ navigate('/') }
    }, [userLogged, navigate])


    return !userLogged ? <Seo title={process.env.REACT_APP_MAIN_TITLE + ' - Register account'} url={process.env.REACT_APP_URL + 'register'}><RegisterForm /></Seo> : <></>
}
