import React  from 'react'
import styled from 'styled-components'
import { useAppOptions } from '../../../contexts/AppOptionsProvider'
import { useLoginPopup } from '../../../contexts/LoginPopupProvider'
import { useUserLang } from '../../../contexts/LanguageSwitcherProvider'
import CountdownTimer from '../../Utils/CountdownTimer/CountdownTimer';

export default function LivePokerLobbyTable(){

    const appOptions = useAppOptions()
    const { lang } = useUserLang()
    const { setLoginPopup } = useLoginPopup()

    const LaunchDate = new Date('January, 01, 2024 10:00:00 GMT+0100');

    return (
        <LobbySection id="poker-lobby">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-11">
                        {appOptions && appOptions.acf.on_holidays ? (
                            <HolidayBox>
                                <Text>We're taking a short break to pop some champagne 🍾 but we can't wait to see you back at the tables.</Text>
                                <Text>HAPPY NEW YEAR!!!</Text>
                                <ImageWrap>
                                    <img className='img-big' src="/images/holiday.jpg" alt="" />
                                    <img className='img-small' src="/images/holiday-mobile.png" alt="" />
                                    <CountdownTimer LaunchDate={LaunchDate}/>
                                </ImageWrap>
                            </HolidayBox>
                        ) : (
                            <TableTypeSection>
                                <Heading>
                                    <div className='iconTitle'>
                                    <Icon width="93" height="92" alt="Live Dealer Tables Icon" src="/images/icons/icon-real-money.svg" />
                                    <Title>
                                        {   appOptions && 
                                            appOptions.acf.poker_tables_lobby && 
                                            appOptions.acf.poker_tables_lobby.live_tables && 
                                            <h3 dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.title }} /> }
                                    </Title>
                                    </div>
                                    <div className='iconDesc'> 
                                    {  appOptions && 
                                            appOptions.acf.poker_tables_lobby && 
                                            appOptions.acf.poker_tables_lobby.live_tables && 
                                            <p dangerouslySetInnerHTML={{ __html: appOptions.acf.poker_tables_lobby.live_tables.description }} /> }
                                    </div>
                                </Heading>
                                <div className="row">
                                    <div className="col-12">
                                        <TableImage onClick={ () => { setLoginPopup(true); }}>
                                            <img src={"/images/lobby-blurred"+ (lang==='en' ? '' : '-' + lang) +".png"} alt="Tabel Live Poker Lobby" />
                                        </TableImage>
                                    </div>
                                </div>
                            </TableTypeSection>
                        )}
                    </div>
                </div>
            </div>
        </LobbySection>
    )
}

const HolidayBox = styled.div `
    text-align: center;
`

const Text = styled.div `
    font-size: 26px;
    max-width: 680px;
    margin: auto;
`

const ImageWrap = styled.div `
    position: relative;

    img {
        width: 100%;
    }

    .img-small {

    }

    .img-big {
        display: none;
    }

    @media (min-width: 992px) {

        .img-small {
            display: none;
        }

        .img-big {
            display: block;
        }
    }

    span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: calc(50% - 21px);
        left: calc(50% - 139px);
        font-size: 36px;
        color: #fdfffe;
        margin: 25px;

        @media (min-width: 992px) {
            bottom: 25px;
            left: 25px;
        }
    }
`

const LobbySection = styled.section`
    padding: 2rem 0;
    padding-bottom: 4rem;
    border-top: 5px solid var(--orange);
    @media (min-width: 0px) and (max-width: 767px){
        padding: 0rem 0;
    }
`

const TableTypeSection = styled.div`
    h3{
        @media (min-width: 0px) and (max-width: 767px){
            font-size: 1.875rem;
        }
        @media (min-width: 768px){
            font-size: 2.5rem;
        }
        margin: 0;
    }
`

const Heading = styled.div`
    margin-top: 4rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 0px) and (max-width: 767px){
        margin-top: 2rem;
        flex-direction: row;
        align-items: flex-start;
    }
    @media (min-width: 768px){
        display: flex;
    }
    h3{
        font-size: 2rem;
        max-width: 200px;
    }
    p{
        margin: 0;
    }
    .iconTitle{
        justify-content: center;
        align-items: center;
        display: flex;
        @media (min-width: 0px) and (max-width: 767px){
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    .iconDesc {
        margin-top: 15px;
        margin-bottom: 0px;
        @media (min-width: 0px) and (max-width: 767px){
            margin-top: 10px;
            margin-bottom: 35px;
        }
    }
`

const Icon = styled.img`
    width: auto;
    @media (min-width: 0px) and (max-width: 767px){
        height: 70px;
        margin-bottom: 0.5rem;
    }
    @media (min-width: 768px){
        height: 90px;
    }
    margin-right: 1rem;
    padding: 0.85rem;
    border-radius: 1rem;
    border: 2px solid var(--orange);
`

const Title = styled.div``

const TableImage = styled.div`
    cursor: pointer;
    img{
        width: 100%;
        height: auto;
    }
`