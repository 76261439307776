import React from 'react'
import styled, { css } from 'styled-components';
import { useAppOptions } from '../../../contexts/AppOptionsProvider';

export default function InfoIconSection() {

    const appOptions = useAppOptions()

    return (
        <>
            <InfoIconSectionWrap>
                <div className="container-liquid">
                    <div className='icon-row row justify-content-center align-items-center'>
                        {
                            appOptions && appOptions.acf.info_icon_section && appOptions.acf.info_icon_section.map((item, index) => {
                                return (
                                    <div key={index} className="col-12 col-lg-4">
                                        <IconBox clickable={item.link} onClick={() => { 
                                            if(item.link){
                                                window.location.href = item.link
                                            }
                                        }}>
                                            <img alt={ item.title } src={ item.icon.url } />
                                            <h3>{ item.title }</h3>
                                            <p>{ item.subtitle }</p>
                                        </IconBox>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </InfoIconSectionWrap>
        </>
    )
}

const InfoIconSectionWrap = styled.section`
    position: relative;
    padding: 4rem 0;
    @media (min-width: 0px) and (max-width: 1199px){
        margin: 0;
    }
    @media (min-width: 1200px){
        margin: 3rem 0;
        .icon-row{
            .col-12:nth-child(2){
                transform: scale(1.15);
            }
        }
    }
    @media (min-width: 0px) and (max-width: 767px){
    padding: 0rem 0;
    }

`

const clickableCss = css`
    @media (min-width: 1200px){
        &:hover{
            cursor: pointer;
            transform: scale(1.05);
        }
    }
`

const IconBox = styled.div`
    max-width: 90%;
    text-align: center;
    padding: 3rem 2.5rem;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    border: 2px solid var(--default);
    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, var(--default-dark) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);

    ${props => props.clickable ? clickableCss : ``};

    overflow: hidden;

    @media (min-width: 0px) and (max-width: 991px){
        margin-bottom: 2rem;
    }
    img{
        width: 7rem;
        padding-bottom: 0;
        height: auto;
    }
    h3, p{
        margin: 0;
    }
    p{
        color: #bbb;
    }

`