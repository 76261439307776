import React, { useEffect, useState, useMemo } from "react"
import { useUserLogged } from "./UserLoggedProvider"
import { useAppLoaded } from "./AppLoadedProvider"

import { useQuickRegisterPopup } from "./LoginPopupProvider"
import axios from "axios"

import Cookies from 'universal-cookie'
import { useUserWalletProvider } from "./UserWalletProvider"

export const UserSessionContext = React.createContext({
	userSession: null,
	setUserSession: () => {},
	customLoginOpened: false,
	setCustomLoginOpened: () => {},
})

export const UserAffiliateMarkerContext = React.createContext({
	affiliateMarker: null,
	setAffiliateMarker: () => {}
})


export const UserSessionProvider = ({ children }) => {
	
    const cookies =  useMemo(() => new Cookies(), []);

	const { setUserWallet } = useUserWalletProvider()
	const { userLogged, setUserLogged } = useUserLogged()
	const { setAppLoaded } = useAppLoaded()
	const { setQuickRegisterPopup } = useQuickRegisterPopup()


    const [ affiliateMarker, setAffiliateMarker ] = useState(cookies.get('affiliateMarker') ? cookies.get('affiliateMarker') : null)

	const [ userSession, setUserSession ] = useState(null)
	/* eslint-disable */
	const [ currency, setCurrency ] = useState('')

	const [ customLoginOpened, setCustomLoginOpened ] = useState(false);


	useEffect(() => {

		//if(!session) return

		
			/* if(!session) return*/
			if(userSession) return 
			
	
			if(localStorage.getItem('authJwt')){

				axios.get(process.env.REACT_APP_API_URL_STAGING + '/api/v1/engine/accounts/profile/profile-data/',{
					headers:{
						"Authorization": "JWT " + localStorage.getItem('authJwt')
					}
				}).then((response) => {
					setUserSession(response.data)
					setUserLogged(true)
					setAppLoaded(true);
					//setUserLogged(true)
				}).catch((error) => {
					console.log(error)
					localStorage.removeItem('authJwt')
					window.location.reload(false)
				})

				
			}else{
				setAppLoaded(true);
			}
			

	}, [userSession, setUserSession, setUserLogged, userLogged])



	useEffect(() => {

		if(!userLogged) return
		if(!localStorage.getItem('authJwt')) return
		//start listening to the watchBalance event
		var currency_symbols = { 'USD': '$', 'EUR': '€', 'GBP': '£', 'INR': '₹', 'RUB': '₽' }

		axios.get(process.env.REACT_APP_API_URL_STAGING + "/api/v1/engine/payments/wallet/deposit-amounts/", {
			headers:{
				"Authorization": "JWT " + localStorage.getItem('authJwt')
			}
		}).then((response) =>{
			setUserWallet({
				amount: response.data.live_deposit,
				bonusAmount: response.data.bonus_deposit,
				freeAmount: response.data.play_money,
				currency: currency_symbols['USD']
			});

			setCurrency(currency_symbols['USD']);
			
		})
		
	}, [userLogged, localStorage.getItem('authJwt')])
	



	function getQueryString(name){
        name = name.replace("/[]/", "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

	useEffect(() => {
        if(cookies.get('affiliateMarker') === undefined && getQueryString('btag') !== ''){
            let d = new Date();
            d.setTime(d.getTime() + (7*24*60*60*1000));
            cookies.set('affiliateMarker',  getQueryString('btag'), {expires: d});
        }else if(cookies.get('affiliateMarker') !== undefined && (cookies.get('affiliateMarker') !== getQueryString('btag') && getQueryString('btag') !== '')){
            let d = new Date();
            d.setTime(d.getTime() + (7*24*60*60*1000));
            cookies.remove('affiliateMarker',{path:'/register'});
            cookies.set('affiliateMarker',  getQueryString('btag'), {expires: d});
        }

        if(getQueryString('btag') !== ''){
            setAffiliateMarker(getQueryString('btag'))
        }

        if(cookies.get('affcode') === undefined && getQueryString('affcode') !== ''){
			setQuickRegisterPopup(true);
            let d = new Date();
            d.setTime(d.getTime() + (7*24*60*60*1000));
            cookies.set('affcode',  getQueryString('affcode'), {expires: d});
        }else if(cookies.get('affcode') !== undefined && (cookies.get('affcode') !== getQueryString('affcode') && getQueryString('affcode') !== '')){
			setQuickRegisterPopup(true);
            let d = new Date();
            d.setTime(d.getTime() + (7*24*60*60*1000));
            cookies.remove('affcode',{path:'/register'});
            cookies.set('affcode',  getQueryString('affcode'), {expires: d});
        }

		if(getQueryString('affcode') !== ''){
			setQuickRegisterPopup(true);
		}

		if(getQueryString('bonusPromo') !== '' && getQueryString('bonusPromo') === 'tfj'){
			setQuickRegisterPopup(true);
		}

		if(getQueryString('affcode') !== ''){
			setQuickRegisterPopup(true);
		}

        if(getQueryString('affcode') !== ''){
            setAffiliateMarker(getQueryString('affcode'))
        }
    }, [cookies])


	useEffect(() => {
		const hashString = window.location.hash.substring(1);
		if (hashString !== "") {
			if(hashString==='quick-register'){
				setQuickRegisterPopup(true)
			}
		}
	}, [])


	return (
		<UserSessionContext.Provider value={{ userSession, setUserSession, customLoginOpened, setCustomLoginOpened }}>
			<UserAffiliateMarkerContext.Provider value={{ affiliateMarker, setAffiliateMarker }}>
				{ children }
			</UserAffiliateMarkerContext.Provider>
		</UserSessionContext.Provider>
	)

}



export const useUserSession = () => {

	const { userSession, setUserSession, customLoginOpened, setCustomLoginOpened } = React.useContext(UserSessionContext)

	if(userSession === undefined && setUserSession === undefined ){
		throw new Error('useUserSession was used outside its Provider!')
	}

	return { userSession, setUserSession, customLoginOpened, setCustomLoginOpened }

}

export const useUserAffiliateMarker = () => {

	const { affiliateMarker, setAffiliateMarker } = React.useContext(UserAffiliateMarkerContext)

	if(affiliateMarker === undefined && setAffiliateMarker === undefined ){
		throw new Error('setAffiliateMarker was used outside its Provider!')
	}

	return { affiliateMarker, setAffiliateMarker }

}